import { createReducer } from "@reduxjs/toolkit";
import { logoutAction, loginAction, removeTokenAction } from "./sessaoActions";
import { SessaoState } from "./sessaoTypes";

export const initialState: SessaoState = {
  usuario: null,
};

const sessaoReducer = createReducer(initialState, {
  [loginAction.toString()]: (state, action) => {
    if (loginAction.match(action)) {
      return { ...state, ...action.payload };
    }
    return { ...state };
  },

  [logoutAction.toString()]: (state, action) => {
    return { ...state, usuario: null, tokenJWT: "" };
  },

  [removeTokenAction.toString()]: (state, action) => {
    return { ...state };
  },
});

export default sessaoReducer;
