import { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import dayjs from "dayjs";
import { AtestadoMedico } from "../../../../../../model/entities/Atestado";
import { DataGrid } from "@mui/x-data-grid";
import atestadoApi from "../../../../../../api/atestadoApi";
import { toast } from "react-toastify";
import { Paciente } from "../../../../../../model/entities/Paciente";
import InfoTabela from "../../../../../Generics/InfoTabela";

const columns: AtestadoMedico[] | any[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "data",
    headerName: "Data da atestado",
    width: 230,
    editable: false,
    renderCell: (params: AtestadoMedico) => {
      return dayjs(params.dataAtestado).format("DD/MM/YYYY");
    }
  },

  {
    field: "motivo",
    headerName: "Motivo",
    width: 300,
    editable: false
  },
  {
    field: "qntDias",
    headerName: "Quantidade de dias",
    width: 200,
    editable: false
  },
  {
    field: "nomePontoAtendimento",
    headerName: "Unidade de atendimento",
    width: 500,
    editable: false
  }
];

type Props = {
  onRowClick: (receita: AtestadoMedico) => void;
  paciente: Paciente;
};

const TabelaAtestadoMedicos: React.FC<Props> = (props) => {
  const { onRowClick, paciente } = props;

  const [totalLinhas, setTotalLinhas] = useState(0);

  const [loading, setLoading] = useState(false);

  const [atestados, setAtestados] = useState<AtestadoMedico[]>([]);

  useEffect(() => {
    buscarAtestados();
  }, []);

  const buscarAtestados = async () => {
    try {
      const resAtestados = await atestadoApi.getByPaciente(paciente.id);
      setAtestados(resAtestados);
    } catch (erro: any) {
      toast.error(`Erro ao buscar informações do paciente`);
    }
  };

  return (
    <Grid container xs={12} sm={12} spacing={2}>
      <InfoTabela mensagem=" Para mais detalhes clique no atestado" />
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        <DataGrid
          pagination={true}
          columns={columns}
          rows={atestados}
          loading={loading}
          rowCount={atestados.length}
          pageSizeOptions={[5, 10, 25]}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } }
          }}
          onRowClick={(params) => onRowClick(params.row as AtestadoMedico)}
          style={{
            height: 400,
            // width: "75%",
            textAlign: "center",
            border: "1px solid black",
            boxShadow: "1px 1px 10px #353353"
          }}
        />
      </Grid>
    </Grid>
  );
};

export default TabelaAtestadoMedicos;
