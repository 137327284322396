import { RouteType } from "../config";
import DashboardPageLayout from "../../components/layout/DashboardPageLayout";
import EncaminhamentosPage from "../../components/App/Pages/Encaminhamento/EncaminhamentosPage";
import ConsultaPage from "../../components/App/Pages/Consulta/ConsultaPage";
import NovoEncaminhamentoPage from "../../components/App/Pages/Encaminhamento/NovoEncaminhamentoPage";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Usuario } from "../../model/entities/Usuario";

const grupoEncaminhamentos: RouteType = {
  path: "/encaminhamentos",
  element: <DashboardPageLayout />,
  state: "encaminhamentos",
  show: true,
  sidebarProps: {
    displayText: "Encaminhamentos",
    icon: <ArrowOutwardIcon />,
  },

  child: [
    {
      index: true,
      element: <EncaminhamentosPage />,
      state: "encaminhamentos.index",
    },
    {
      path: "/encaminhamentos/listagem",
      element: <EncaminhamentosPage />,
      state: "encaminhamentos.listagem",
      verificarPermissao: (user: Usuario | null) => {
        return Boolean(user && user.perfil.id !== 2);
      },
      sidebarProps: {
        displayText: "Lista encaminhamentos",
      },
    },
    {
      path: "/encaminhamentos/cadastro",
      element: <NovoEncaminhamentoPage />,
      state: "encaminhamentos.cadastro",

      sidebarProps: {
        displayText: "Novo Encaminhamento",
      },
    },

    {
      path: "/encaminhamentos/:id",
      element: <ConsultaPage />,
      state: "encaminhamentos.id",
    },
  ],
};

export default grupoEncaminhamentos;
