import { Medico } from "./Medico";
import { PontoAtendimento } from "./PontoAtendimento";

export class Receita {
  id!: number;

  idMedico!: number;

  idPaciente!: number;

  prescricao!: string;

  data!: Date;

  idPontoAtendimento!: number;

  pontoAtendimento!: PontoAtendimento;

  medico!: Medico;
}
