import { makeStyles } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

type Props = {
  extensaoArquivo: string[];
  setFile: (file: File | null) => void;
};

const DropZone: React.FC<Props> = (props) => {
  const { extensaoArquivo, setFile } = props;
  const classes = useStyles();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (!extensaoArquivo.includes(acceptedFiles[0].type.split("/")[1])) {
        toast.error("Extensão de arquivo não suportada.");
        return;
      }
      setSelectedFile(acceptedFiles[0] || null);
      setFile(acceptedFiles[0] || null);
    },
    [setFile]
  );

  // const myAccept: Accept = { key1: extensaoArquivo };

  const { getRootProps, isDragActive } = useDropzone({
    onDrop
    // accept: myAccept
  });

  return (
    <div
      style={{
        border: "2px dashed #ccc",
        backgroundColor: "#f9f9f9",
        textAlign: "center",
        cursor: "pointer",
        borderRadius: "4px",
        height: "100px"
      }}
      onChange={() => {}}
      {...getRootProps()}
    >
      <label
        style={{
          display: "block",
          padding: "25px",
          fontSize: "16px",
          color: "#888"
        }}
      >
        {selectedFile ? (
          <span className={classes.p}>{selectedFile?.name ?? ""}</span>
        ) : isDragActive ? (
          <span className={classes.p}>Solte aqui o arquivo .xml ...</span>
        ) : (
          <span className={classes.p}>
            Arraste e solte aqui o arquivo, ou clique para selecioná-lo
          </span>
        )}
      </label>
    </div>
  );
};

export default DropZone;

const useStyles = makeStyles({
  p: {
    fontSize: "16px",
    color: "#888",
    justifyItems: "center",
    marginTop: "4%",
    cursor: "pointer"
  }
});
