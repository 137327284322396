import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import DashboardPageLayout from "../../components/layout/DashboardPageLayout";
import { RouteType } from "../config";
import PacientesPage from "../../components/App/Pages/Pacientes/PacientesPage";
import PacienteProntuario from "../../components/App/Pages/Pacientes/Prontuario/PacienteProntuario";

const grupoPacientes: RouteType = {
  path: "/pacientes",
  element: <DashboardPageLayout />,
  state: "pacientes",
  show: true,
  sidebarProps: {
    displayText: "Pacientes",
    icon: <PersonOutlineIcon />
  },
  child: [
    {
      index: true,
      element: <DashboardPageLayout />,
      state: "pacientes.index"
    },
    {
      path: "/pacientes/listagem",
      element: <PacientesPage />,
      state: "pacientes.listagem",
      sidebarProps: {
        displayText: "Lista Pacientes"
      }
    },
    {
      path: "/pacientes/:id",
      element: <PacienteProntuario />,

      state: "pacientes.id"
    }
  ]
};

export default grupoPacientes;
