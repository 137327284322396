import { createReducer } from "@reduxjs/toolkit";
import { setCfgGeralAction } from "./cfgGeralActions";
import { CfgGeralState } from "./cfgGeralTypes";

export const initialState: CfgGeralState = {
  urlApi: "http://localhost:4500",
  versao: '"0.1.0',
};

const cfgGeralReducer = createReducer<CfgGeralState>(initialState, {
  [setCfgGeralAction.toString()]: (state, action) => {
    if (setCfgGeralAction.match(action)) {
      return { ...state, ...action.payload };
    }
    return { ...state };
  },
});

export default cfgGeralReducer;
