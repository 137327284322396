import { Receita } from "../../../../../../model/entities/Receita";
import { Grid } from "@mui/material";
import TabelaReceitas from "./TabelaReceitas";
import { useState } from "react";
import ModalGeneric from "../../../../../Generics/ModalGeneric";
import Receituario from "../../../Consulta/Receituario";
import { Paciente } from "../../../../../../model/entities/Paciente";

type Props = {
  paciente: Paciente;
};

const ReceitasProntuario: React.FC<Props> = (props) => {
  const { paciente } = props;

  const [receitaSelecionada, setReceitaSelecionada] = useState<Receita | null>(
    null
  );

  return (
    <Grid>
      <Grid container spacing={2} style={{ margin: "15px 5px 0px 5px" }}>
        <TabelaReceitas
          paciente={paciente}
          onRowClick={(value) => {
            setReceitaSelecionada(value);
          }}
        />

        {receitaSelecionada && (
          <ModalGeneric
            open={Boolean(receitaSelecionada)}
            onClose={() => setReceitaSelecionada(null)}
          >
            <Receituario
              receita={receitaSelecionada}
              paciente={paciente}
              setReceita={() => {}}
              apenasVisualizacao
            />
          </ModalGeneric>
        )}
      </Grid>
    </Grid>
  );
};

export default ReceitasProntuario;
