import { Button, Grid } from "@mui/material";

import { useNavigate } from "react-router";
import SaveIcon from "@mui/icons-material/Save";

import { Perfil } from "../../../../../model/entities/Perfil";

import TextFieldGeneric from "../../../../Generics/TextFieldGeneric";
import CheckboxGeneric from "../../../../Generics/CheckBox";
import { toast } from "react-toastify";
import perfilApi from "../../../../../api/perfilApi";
import { useState } from "react";

type Props = {
  perfil: Perfil;
  setPerfil: (p: Perfil) => void;
};

const FormularioConfiguracaoPerfil: React.FC<Props> = (props) => {
  const { perfil, setPerfil } = props;

  const [loading, setLoading] = useState(false);

  const salvar = async () => {
    try {
      if (!perfil.id) {
        const criaPerfil = perfilApi.insert({
          descricao: perfil.descricao,
          podeCadastrarEncaminhamento: perfil.podeCadastrarEncaminhamento,
          podePreescreverReceita: perfil.podePreescreverReceita,
          podePreescreverAtestado: perfil.podePreescreverAtestado,
        });

        toast.promise(criaPerfil, {
          pending: {
            render() {
              setLoading(true);
              return "Criando perfil";
            },
          },
          success: {
            render() {
              setLoading(false);
              return "Perfil criado!";
            },
          },
          error:
            "Erro ao cadastrar encaminhamento. A equipe de TI, foi notificada",
        });
      } else {
        const atualizaPerfil = perfilApi.update({
          id: perfil.id,
          descricao: perfil.descricao,
          podeCadastrarEncaminhamento: perfil.podeCadastrarEncaminhamento,
          podePreescreverReceita: perfil.podePreescreverReceita,
          podePreescreverAtestado: perfil.podePreescreverAtestado,
        });

        toast.promise(atualizaPerfil, {
          pending: {
            render() {
              setLoading(true);
              return "Atualizando perfil";
            },
          },
          success: {
            render() {
              setLoading(false);
              return "Perfil atualizado";
            },
          },
          error:
            "Erro ao cadastrar encaminhamento. A equipe de TI, foi notificada",
        });
      }
    } catch (erro: any) {
      toast.error("Erro ao salvar perfil");
      console.error("Erro ao salvar perfil", erro);
    }
  };

  return (
    <Grid container xs={12} sm={12} spacing={2} style={{ padding: "2%" }}>
      <Grid item xs={12}>
        <h3>Configurações de perfil - {perfil.descricao}</h3>
      </Grid>

      <Grid item xs={12}>
        <TextFieldGeneric
          label="Nome"
          onChange={(value) => setPerfil({ ...perfil, descricao: value })}
          value={perfil.descricao}
        />
      </Grid>
      {/* 
      <Grid item xs={6}>
        <AcessoVinculadoOuTodos
          acesso={perfil.acessoPontoAtendimentos}
          label="Acesso a pontos de atendimentos"
          setAcesso={(n) => {
            if (n) setPerfil({ ...perfil, acessoPontoAtendimentos: n });
          }}
        />
      </Grid> */}

      <Grid item xs={12}>
        <CheckboxGeneric
          checked={perfil.podeCadastrarEncaminhamento}
          onChange={(x) =>
            setPerfil({ ...perfil, podeCadastrarEncaminhamento: x })
          }
          label="Pode cadastrar encaminhamentos"
        />
      </Grid>

      <Grid item xs={12}>
        <CheckboxGeneric
          label="Pode preescrever atestado"
          checked={perfil.podePreescreverAtestado}
          onChange={(x) => setPerfil({ ...perfil, podePreescreverAtestado: x })}
        />
      </Grid>

      <Grid item xs={12}>
        <CheckboxGeneric
          label="Pode preescrever receita"
          checked={perfil.podePreescreverReceita}
          onChange={(x) => setPerfil({ ...perfil, podePreescreverReceita: x })}
        />
      </Grid>

      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Button
          startIcon={<SaveIcon />}
          onClick={() => {
            salvar();
          }}
          variant="contained"
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};

export default FormularioConfiguracaoPerfil;
