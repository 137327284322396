import { makeStyles, Typography } from "@material-ui/core";
import React, { ReactNode } from "react";

type Props = {
  label: string;
  children: ReactNode;
  object?: boolean;
};

const Info: React.FC<Props> = (props) => {
  const { label, children, object, ...other } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.label}>{label}</Typography>
      {object === true ? (
        children
      ) : (
        <Typography className={classes.info}>
          {children ? children : "⠀"}
        </Typography>
      )}
    </div>
  );
};

Info.propTypes = {
  // // classes: PropTypes.object.isRequired,
  // value: PropTypes.oneOfType([
  //   PropTypes.string,
  //   PropTypes.number
  // ]),
  // onChange: PropTypes.func.isRequired,
  // label: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  container: {
    // display: "flex"
    width: "100%",
    height: "100%",
    backgroundColor: "#f5f5f5",
    padding: 7,
    borderRadius: 5,
  },
  label: {
    // fontWeight: '',
    fontWeight: 700,
    fontSize: 12,
  },
  info: {
    fontSize: 16,
  },
});

export default Info;
