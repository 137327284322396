import { Receita } from "../model/entities/Receita";
import { DadosImprimirReceita } from "../model/types/DadosImprimirReceita";
import { ResponseGenericType } from "../model/types/ResponseType";
import { FindManyReq } from "../model/types/TypeormTypes";
import api from "./api";

const PREFIX = "";
const ROUTE = "receita";

class receitaApi {
  async getMany() {
    try {
      const res = await api.get<ResponseGenericType<Receita[]>>(`/${ROUTE}`);
      const data = res.data.content;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getByPaciente(idPaciente: number) {
    try {
      const res = await api.get<Receita[]>(
        `/${ROUTE}/getByPaciente/${idPaciente}`
      );
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async count() {
    try {
      const res = await api.get<number>(`/${ROUTE}/count`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async insert(receita: Receita) {
    try {
      const res = await api.post<ResponseGenericType<Receita[]>>(
        `/${ROUTE}`,
        receita
      );

      const data = res.data.content;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
  async imprimir(dados: DadosImprimirReceita) {
    try {
      const res = await api.post<string>(`/${ROUTE}/imprimir`, dados, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      });

      const blob = new Blob([res.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      // Abra o PDF em uma nova janela ou guia
      window.open(url, "_blank");
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new receitaApi();
