import { ResBuscaCep } from "../model/types/BuscaCepTypes";
import api from "./api";

class cepApi {
  async buscaCep(cep: string) {
    try {
      const res = await api.get<ResBuscaCep>(
        `https://cdn.apicep.com/file/apicep/${cep}.json`
      );
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new cepApi();
