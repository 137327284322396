import { useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";

import { Usuario } from "../../../../../model/entities/Usuario";
import PerfilField from "../../../../Generics/Fields/PerfilField";
import { Perfil } from "../../../../../model/entities/Perfil";

import PontoAtendimentoField from "../../../../Generics/Fields/PontoAtendimentoField";
import TextFieldGeneric from "../../../../Generics/TextFieldGeneric";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import { toast } from "react-toastify";
import usuarioApi from "../../../../../api/usuarioApi";

type Props = {
  usuario: Usuario;
  setUsuario: (p: Usuario) => void;
};

const FormularioConfiguracaoUsuario: React.FC<Props> = (props) => {
  const { usuario, setUsuario } = props;
  const [verSenha, setVerSenha] = useState(false);

  const [perfilSelecionado, setPerfilSelecionado] = useState<Perfil>(
    usuario.perfil
  );

  const salvar = async () => {
    try {
      if (usuario.id) {
        await usuarioApi.update({
          id: usuario.id,
          login: usuario.login,
          senha: usuario.senha,
          nome: usuario.nome,
          perfilId: usuario.perfil.id,
          pontoAtendimentoId: usuario.pontoAtendimento.id,
        });
      } else {
        await usuarioApi.insert({
          login: usuario.login,
          senha: usuario.senha,
          nome: usuario.nome,
          perfilId: perfilSelecionado.id,
          pontoAtendimentoId: usuario.pontoAtendimento.id,
        });
      }
      toast.success("Cadastro de usuário salvo com sucesso!");
    } catch (erro: any) {
      toast.error("Erro ao salvar o cadastro do usuário");
      console.log("Erro ao salvar o cadastro do usuário", erro);
    }
  };

  return (
    <Grid container xs={12} sm={12} spacing={2}>
      <Grid item xs={12}>
        <h3>Configurações do usuário - {usuario.nome}</h3>
      </Grid>

      <Grid item xs={12}>
        <TextFieldGeneric
          label="Nome"
          onChange={(value) => setUsuario({ ...usuario, nome: value })}
          value={usuario.nome}
        />
      </Grid>

      <Grid item xs={6}>
        <TextFieldGeneric
          label="LOGIN"
          onChange={(value) => setUsuario({ ...usuario, login: value })}
          value={usuario.login}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Senha"
          value={usuario.senha}
          onChange={(event) =>
            setUsuario({ ...usuario, senha: event.target.value })
          }
          type={verSenha ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setVerSenha(!verSenha)}
                  // onMouseDown={handleMouseDownPassword}
                >
                  {verSenha ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <PerfilField
          perfil={perfilSelecionado}
          setPerfil={(p) => {
            if (p) setPerfilSelecionado(p);
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <PontoAtendimentoField
          pontoAtendimento={usuario.pontoAtendimento}
          setPontoAtendimento={(p) => {
            if (p) setUsuario({ ...usuario, pontoAtendimento: p });
          }}
        />
      </Grid>

      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Button
          startIcon={<SaveIcon />}
          onClick={() => salvar()}
          variant="contained"
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};

export default FormularioConfiguracaoUsuario;
