import { JoinColumn, ManyToOne, OneToMany } from "typeorm";
import { ContraReferencia } from "./ContraReferencia";
import { Paciente } from "./Paciente";

export class Encaminhamento {
  id!: number;

  nomeCidadao!: string;

  cpfCns!: string;

  classificacaoRisco!: string;

  genero!: string;

  dataNascimento!: Date;

  telefone!: string;

  nomeMae!: string;

  municipioNascimento!: string;

  unidadeSolicitante!: string;

  cnes!: string;

  profissionalSolicitante!: string;

  cns!: string;

  especialidade!: string;

  hipotese!: string;

  motivoEncaminhamento!: string;

  observacao!: string;

  contraReferencia?: ContraReferencia;

  dataEncaminhamento!: Date;

  pacienteAusente!: boolean;

  motivoAusenciaPaciente!: string;

  idPaciente!: number;

  paciente!: Paciente;
}
