import * as React from "react";
import TextField from "@mui/material/TextField";
import { Usuario } from "../../../model/entities/Usuario";
import { useEffect, useState } from "react";
import { Autocomplete } from "@mui/material";
import pacienteApi from "../../../api/pacienteApi";
import usuarioApi from "../../../api/usuarioApi";
import theme from "../../../theme";

type Props = {
  setUsuario: (u: Usuario | null) => void;
};

const UsuariosField: React.FC<Props> = (props) => {
  const { setUsuario } = props;

  const [usuarios, setUsuarios] = useState<Usuario[]>([]);

  useEffect(() => {
    buscarUsuarios();
  }, []);

  const buscarUsuarios = async () => {
    try {
      const res = await usuarioApi.getMany();
      setUsuarios(res);
    } catch (erro: any) {
      console.log("Erro ao buscar usuarios.");
    }
  };
  const xsValue = theme.breakpoints.down("sm") ? 12 : 6;

  return (
    <div style={{ textAlign: "center" }}>
      <Autocomplete
        id="filter-usuarios"
        options={usuarios}
        getOptionLabel={(option) => option.login}
        onChange={(event, value) => {
          if (value) setUsuario(value);
          else setUsuario(null);
        }}
        sx={{ xsValue }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={"outlined"}
            label={"Usuários"}
            placeholder={"Usuario"}
            multiline={true}
          />
        )}
      />
    </div>
  );
};

export default UsuariosField;
