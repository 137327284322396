import { Button, Grid } from "@mui/material";
import { PontoAtendimento } from "../../../../../model/entities/PontoAtendimento";
import TextFieldGeneric from "../../../../Generics/TextFieldGeneric";
import TipoPontoAtendimentoField from "../../../../Generics/TipoPontoAtendimentoField";
import { useEffect, useState } from "react";
import { Endereco } from "../../../../../model/entities/Endereco";
import { toast } from "react-toastify";
import SaveIcon from "@mui/icons-material/Save";
import Enumerador from "../../../../Generics/Enumerador";
import Container from "../../../../Generics/Container";
import pontoAtendimentoApi from "../../../../../api/pontoAtendimentoApi";
import buscaCepApi from "../../../../../api/buscaCepApi";

type Props = {
  pontoAtendimentoSelecionado: PontoAtendimento;
};

const FormularioConfiguracaoPontoAtendimento: React.FC<Props> = (props) => {
  const { pontoAtendimentoSelecionado } = props;

  const [pontoAtendimento, setPontoAtendimento] = useState<PontoAtendimento>(
    new PontoAtendimento()
  );
  const [endereco, setEndereco] = useState<Endereco>(new Endereco());

  const [isNomeEmpty, setIsNomeEmpty] = useState(false);
  const [isTipoEmpty, setIsTipoEmpty] = useState(false);

  useEffect(() => {
    setPontoAtendimento(pontoAtendimentoSelecionado);
    if (pontoAtendimentoSelecionado.endereco) {
      setEndereco(pontoAtendimentoSelecionado.endereco);
    } else {
      setEndereco({ ...new Endereco() });
    }
  }, [pontoAtendimentoSelecionado]);

  const salvar = async () => {
    try {
      if (pontoAtendimento.id) {
        await pontoAtendimentoApi.update({
          dadosEndereco: endereco,
          id: pontoAtendimento.id,
          nome: pontoAtendimento.nome,
          nomeResponsavel: pontoAtendimento.nomeResponsavel,
          telefone: pontoAtendimento.telefone,
          tipo: pontoAtendimento.tipo,
          cnes: pontoAtendimento.cnes,
        });
      } else {
        if (!verificaRegras()) return;
        await pontoAtendimentoApi.insert({
          dadosEndereco: endereco,
          nome: pontoAtendimento.nome,
          nomeResponsavel: pontoAtendimento.nomeResponsavel,
          telefone: pontoAtendimento.telefone,
          tipo: pontoAtendimento.tipo,
          cnes: pontoAtendimento.cnes,
        });
      }

      toast.success("Ponto de atendimento salvo com sucesso");
      window.location.reload();
    } catch (erro: any) {
      toast.error(
        "Aconteceu um erro ao salvar o cadastro do ponto de atendimento"
      );
      console.log(
        "Aconteceu um erro ao salvar o cadastro do ponto de atendimento",
        erro
      );
    }
  };

  const verificaRegras = () => {
    if (!pontoAtendimento.nome) {
      setIsNomeEmpty(true);
      toast.error("Por favor informe o nome do P.A");
      return false;
    }

    if (!pontoAtendimento.tipo) {
      setIsTipoEmpty(true);
      toast.error("Por favor informe o tipo do P.A");
      return false;
    }

    return true;
  };

  const buscaCep = async () => {
    const res = await buscaCepApi.buscaCep("35570342");
    console.log("res", res);
  };
  return (
    <Grid container spacing={2} xs={12} sm={12} style={{}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Enumerador
            numero={1}
            label={`Configurações de pontoAtendimento - ${
              pontoAtendimento.nome ?? "Novo P.A"
            }`}
          />
        </Grid>

        <Grid item xs={6}>
          <TextFieldGeneric
            label="Nome unidade sáude *"
            value={pontoAtendimento.nome}
            onChange={(nome) => {
              if (nome.trim() === "") setIsNomeEmpty(true);
              if (nome.trim() !== "") setIsNomeEmpty(false);
              setPontoAtendimento({ ...pontoAtendimento, nome: nome });
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextFieldGeneric
            label="CNES"
            value={pontoAtendimento.cnes}
            onChange={(nome) =>
              setPontoAtendimento({ ...pontoAtendimento, cnes: nome })
            }
          />
        </Grid>

        <Grid item xs={6}>
          <TextFieldGeneric
            label="Nome responsável"
            value={pontoAtendimento.nomeResponsavel}
            onChange={(nome) =>
              setPontoAtendimento({
                ...pontoAtendimento,
                nomeResponsavel: nome,
              })
            }
          />
        </Grid>

        <Grid item xs={6}>
          <TipoPontoAtendimentoField
            helperText={isTipoEmpty ? "Informe o tipo do P.A" : ""}
            error={isTipoEmpty}
            tipo={pontoAtendimento.tipo}
            setTipo={(tp) => {
              if (tp)
                setPontoAtendimento({ ...pontoAtendimento, tipo: tp?.value });
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextFieldGeneric
            label="Telefone"
            value={pontoAtendimento.telefone}
            onChange={(nome) =>
              setPontoAtendimento({
                ...pontoAtendimento,
                telefone: nome,
              })
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} style={{ marginTop: "2%" }}>
          <Enumerador numero={2} label="Endereço" />
        </Grid>

        <Grid item xs={6}>
          <TextFieldGeneric
            label="CEP"
            value={endereco.cep}
            onChange={(value) => setEndereco({ ...endereco, cep: value })}
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <TextFieldGeneric
            label="Logradouro"
            value={endereco.logradouro}
            onChange={(value) =>
              setEndereco({ ...endereco, logradouro: value })
            }
          />
        </Grid>

        <Grid item xs={6}>
          <TextFieldGeneric
            label="Numero"
            value={endereco.numero}
            onChange={(value) => setEndereco({ ...endereco, numero: value })}
          />
        </Grid>

        <Grid item xs={6}>
          <TextFieldGeneric
            label="Bairro"
            value={endereco.bairro}
            onChange={(value) => setEndereco({ ...endereco, bairro: value })}
          />
        </Grid>

        <Grid item xs={6}>
          <TextFieldGeneric
            label="Cidade"
            value={endereco.cidade}
            onChange={(value) => setEndereco({ ...endereco, cidade: value })}
          />
        </Grid>

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            startIcon={<SaveIcon />}
            onClick={() => {
              salvar();
            }}
            variant="contained"
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormularioConfiguracaoPontoAtendimento;
