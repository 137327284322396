export class SolicitacaoInternacao {
  id!: number;
  idPaciente!: number;
  idMedico!: number;
  idPontoAtendimento!: number;
  principaisSintomas!: string;
  principaisResultado!: string;
  diagnosticoInicail!: string;
  cidPrincipal!: string;
  cidSecundario!: string;
  cidCausasAssociadas!: string;
  procedimentoSolicitado!: string;
  codigoProcedimento!: string;
  clinica!: string;
  caraterInternacao!: string;
  cpfCns!: number; //1 CNS, 2 CPF
  documentoProfissionalSolicitanteAssistente!: string;
  nomeProfissionalSolicitanteAssistente!: string;
  dataSolicitacao!: Date;
  acidente!: string; // 1 transito, 2 trabalhoTipico, 3 trabalhoTrajeto
  cnpjSeguradora!: string;
  noBilhete!: string;
  serie!: string;
  cnpjEmpresa!: string;
  cnaeEmpresa!: string;
  cbor!: string;
  nomeProfissionalAutorizador!: string;
  codOrgaoEmissor!: string;
  documento!: string;
  documentoProfissionalAutorizador!: string;
  dataAutorizacao!: Date;
  noAutInternacao!: string;
}
