import { Grid, IconButton } from "@mui/material";

import EncaminhamentoIcon from "../icons/EncaminhamentoIcon";
import PatientIcon from "../icons/PatientIcon";

import { useNavigate } from "react-router-dom";
import { useSessao } from "../../redux/sessao/sessaoHooks";
import Container from "../Generics/Container";

import Timbre from "../../images/timbre.png";
import ListIcon from "../icons/ListIcon";

type Props = {};
const HomePage: React.FC<Props> = () => {
  const navigate = useNavigate();

  const [sessao] = useSessao();

  return (
    <Container style={{ height: "88vh" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <img src={Timbre} alt="timbre" />
          <hr />
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ textAlign: "center" }}>
        <h2>
          {(() => {
            const horaAtual = new Date().getHours();
            if (horaAtual >= 5 && horaAtual < 12) {
              return `Bom dia ${sessao.usuario?.nome}!`;
            } else if (horaAtual >= 12 && horaAtual < 18) {
              return `Boa tarde ${sessao.usuario?.nome}!`;
            } else {
              return `Boa noite ${sessao.usuario?.nome}!`;
            }
          })()}
        </h2>
      </Grid>

      {sessao.usuario?.perfil.id !== 2 && (
        <Grid item xs={12} style={{ display: "flex" }}>
          <Grid
            item
            xs
            style={{
              margin: "5% 50px ",
              height: "250px",
              width: "35%",
              backgroundPosition: "center",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <h1 style={{ color: "black" }}>Pacientes</h1>
            <IconButton onClick={() => navigate("/pacientes/listagem")}>
              <PatientIcon fill={""} width={350} height={250} />
            </IconButton>
          </Grid>

          <Grid
            item
            xs
            style={{
              margin: "5% 0px ",
              height: "250px",
              width: "35%",
              backgroundPosition: "center",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <h1 style={{ color: "black" }}>Encaminhamentos</h1>

            <IconButton
              onClick={() => {
                navigate("/encaminhamentos/listagem");
              }}
            >
              <ListIcon fill={""} width={350} height={250} />
            </IconButton>
          </Grid>
        </Grid>
      )}
      {sessao.usuario?.perfil.id === 2 && (
        <Grid item xs={12} style={{ display: "flex" }}>
          <Grid
            item
            xs
            style={{
              margin: "5% 50px ",
              height: "250px",
              width: "35%",
              backgroundPosition: "center",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <h1 style={{ color: "black" }}>Pacientes</h1>
            <IconButton onClick={() => navigate("/pacientes/listagem")}>
              <PatientIcon fill={""} width={350} height={250} />{" "}
            </IconButton>
          </Grid>

          <Grid
            item
            xs
            style={{
              margin: "5% 0px ",
              height: "250px",
              width: "35%",
              backgroundPosition: "center",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <h1 style={{ color: "black" }}>Novo encaminhamento</h1>

            <IconButton
              onClick={() => {
                navigate("encaminhamentos/cadastro");
                // setOpenModalEncaminhamento(true);
              }}
            >
              <EncaminhamentoIcon fill={""} width={350} height={250} />{" "}
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default HomePage;
