import { useEffect, useState } from "react";
import { Button, Grid, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import { Encaminhamento } from "../../../../model/entities/Encaminhamento";
import TextFieldGeneric from "../../../Generics/TextFieldGeneric";
import { CalendarX, PaperPlaneRight, X } from "@phosphor-icons/react";
import { useNavigate } from "react-router";
import encaminhamentoApi, {
  statusEncaminhamento,
} from "../../../../api/encaminhamentoApi";
import dayjs from "dayjs";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import ModalGeneric from "../../../Generics/ModalGeneric";
import { DomainVerificationOutlined } from "@mui/icons-material";
import TableGeneric from "../../../Generics/TableGeneric";

type Props = {};

const EncaminhamentosPage: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const [encaminhamentos, setEncaminhamentos] = useState<Encaminhamento[]>([]);
  const [totalLinhas, setTotalLinhas] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openModalMarcarAusencia, setOpenModalMarcarAusencia] = useState(false);
  const [motivoAusencia, setMotivoAusencia] = useState("");
  const [encaminhamentoSelecionado, setEncaminhamentoSelecionado] =
    useState<Encaminhamento | null>(null);

  const [nomePaciente, setNomePaciente] = useState("");

  useEffect(() => {
    buscarEncaminhamentos();
  }, []);

  const buscarEncaminhamentos = async () => {
    try {
      setLoading(true);
      // const tot = await encaminhamentoApi.count({});
      setTotalLinhas(2);
      const res = await encaminhamentoApi.getAll();
      setEncaminhamentos(res);

      setLoading(false);
    } catch (erro: any) {
      toast.error(
        "Ops); Aconteceu um problema ao buscar os encaminhamentos !!"
      );
      console.log("erro ao buscar encaminhamentos", erro);
      setLoading(false);
    }
  };

  const navegarParaEncaminhamento = (
    encaminhamentoSelecionado: Encaminhamento
  ) => {
    console.log("encaminhamentoSelecionado", encaminhamentoSelecionado);
    if (encaminhamentoSelecionado)
      navigate(`/encaminhamentos/${encaminhamentoSelecionado.id}`, {
        state: { encaminhamentoSelecionado },
      });
  };

  const columns: any[] = [
    {
      field: "dataEncaminhamento",
      headerName: "Data encaminhamento",
      width: 150,
      renderCell: (params: any) => {
        return dayjs(params.row.dataEncaminhamento).format("DD/MM/YYYY");
      },
    },
    {
      field: "nomeCidadao",
      headerName: "Nome cidadão",
      width: 230,
      editable: false,
    },
    {
      field: "profissionalSolicitante",
      headerName: "Profissional Solicitante",
      width: 250,
    },
    {
      field: "hipotese",
      headerName: "Hipótese / Diagnóstico (CID10)",
      width: 280,
    },
    {
      field: "motivoEncaminhamento",
      headerName: "Motivo Encaminhamento",
      width: 280,
    },
    {
      // esta dando leitura em objeto nullo
      field: "marcarAusencia",

      headerName: "Marcar Ausência do paciente",
      width: 250,
      renderCell: (params: any) => {
        return (
          <>
            {params.row.contraReferencia.diagnostico ? (
              <span style={{ color: "green" }}> Concluído</span>
            ) : (
              params.row.pacienteAusente && (
                <span style={{ color: "red" }}>Ausente</span>
              )
            )}
            {!params.row.pacienteAusente &&
              !params.row.contraReferencia.diagnostico && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenModalMarcarAusencia(true);
                    setEncaminhamentoSelecionado(params.row as Encaminhamento);
                  }}
                >
                  <CalendarX size={32} />
                </IconButton>
              )}
          </>
        );
      },
    },
    {
      field: "pacienteAusente",
      headerName: "Ausente",
      width: 100,
      renderCell: (params: any) => {
        return <>{params.row.pacienteAusente && <h1>SIM</h1>}</>;
      },
    },

    {
      field: "Status",
      headerName: "Status",
      width: 280,
      renderCell: (params: any) => {
        return statusEncaminhamento(params.row as Encaminhamento);
      },
    },
  ];

  const marcarAusencia = async () => {
    try {
      if (!encaminhamentoSelecionado) return;
      await encaminhamentoApi.update(encaminhamentoSelecionado.id, {
        motivoAusenciaPaciente: motivoAusencia,
        pacienteAusente: true,
      });
      toast.success("Ausência do paciente confirmada");
      setOpenModalMarcarAusencia(false);
      await buscarEncaminhamentos();
    } catch (erro: any) {
      toast.error(
        "Um erro inesperado aconteceu ao marcar a ausência do paciente."
      );
      console.log("Erro ao confirmar ausência do paciente", erro);
    }
  };

  return (
    <Grid container xs={12} sm={12} spacing={2}>
      <Grid
        item
        xs={12}
        style={{
          margin: "4% 4% 2% 4%",
          padding: "2px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={12} style={{ marginRight: "2%" }}>
          <TextFieldGeneric
            label="Nome Cidadão"
            value={nomePaciente}
            onChange={(value) => {
              setNomePaciente(value);
            }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          marginLeft: "15px",
        }}
      >
        <TableGeneric
          colunas={columns}
          pageSizeOptions={[10]}
          onPageChange={() => {}}
          linhas={encaminhamentos}
          loading={loading}
          totalLinhas={totalLinhas}
          onRowClick={(params) => {
            navegarParaEncaminhamento(params.row as Encaminhamento);
          }}
        />
      </Grid>

      {openModalMarcarAusencia && encaminhamentoSelecionado && (
        <ModalGeneric
          open={Boolean(openModalMarcarAusencia && encaminhamentoSelecionado)}
          onClose={() => setOpenModalMarcarAusencia(false)}
        >
          <Grid container spacing={2} style={{ textAlign: "center" }}>
            <Grid item xs={12}>
              <h2>
                Confirma ausência do paciente {""}
                {encaminhamentoSelecionado.nomeCidadao} ?
              </h2>
            </Grid>

            <Grid item xs={12}>
              <h3> Caso tenha motivo informe abaixo</h3>
            </Grid>

            <Grid item xs={12}>
              <TextFieldGeneric
                label="Motivo ausência"
                value={motivoAusencia}
                onChange={(value) => setMotivoAusencia(value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                onClick={() => {
                  marcarAusencia();
                }}
                variant="contained"
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </ModalGeneric>
      )}
    </Grid>
  );
};

export default EncaminhamentosPage;
