import { Card, Grid, IconButton } from "@mui/material";
import { makeStyles } from "@material-ui/core";

import dayjs from "dayjs";
import Container from "../../../Generics/Container";
import { Paciente } from "../../../../model/entities/Paciente";
import TextFieldGeneric from "../../../Generics/TextFieldGeneric";

import RadioButonGroupGeneric from "../../../Generics/RadioButonGroupGeneric";
import GenericDatePicker from "../../../Generics/DatePickerGeneric";
import { useNavigate } from "react-router-dom";
import { Printer } from "@phosphor-icons/react";

import CheckboxGeneric from "../../../Generics/CheckBox";
import { EncaminhamentoFisioterapia } from "../../../../model/entities/EncaminhamentoFisioterapia";
import {
  INICIO_SINTOMAS,
  SITUACAO_MERCADO_TRABALHO
} from "../../../../constants";
import { useEffect } from "react";
import { Encaminhamento } from "../../../../model/entities/Encaminhamento";

type Props = {
  paciente: Paciente;
  encaminhamentoSelecionado: Encaminhamento;
  encaminhamentoFisio: EncaminhamentoFisioterapia;
  setEncaminhamentoFisio: (e: EncaminhamentoFisioterapia) => void;
};

const EncaminhamentoFisioterapiaPage: React.FC<Props> = (props) => {
  const {
    paciente,
    encaminhamentoFisio,
    setEncaminhamentoFisio,
    encaminhamentoSelecionado
  } = props;

  const navigate = useNavigate();

  useEffect(() => {
    setEncaminhamentoFisio({
      ...encaminhamentoFisio,
      idMedico: 1,
      idPontoAtendimento: 1,
      idPaciente: 1,
      dataSolicitacao: dayjs().toDate()
    });
  }, []);

  return (
    <Grid display={"flex"} spacing={2}>
      <Grid item xs={2} style={{}}>
        <Card
          style={{
            cursor: "pointer",
            marginRight: "15px",
            display: "flex",
            flexDirection: "column",
            margin: "0px 1rem 0px 1px",
            boxShadow: "1px 1px 5px #353353",
            // background: "red",
            position: "sticky",
            top: "0"
          }}
        >
          <a
            href="#avaliacao"
            onClick={() => {
              navigate(
                `/encaminhamento/${encaminhamentoSelecionado.id}/#avaliacao`,
                {
                  state: { encaminhamentoSelecionado }
                }
              );
              // setExpandirProcedimentoSolicitado(true);
            }}
            style={{
              color: "inherit",
              textDecoration: "inherit",
              margin: "5px",
              padding: "1px"
            }}
          >
            Avaliação do paciente
          </a>

          <a
            href="#situacao"
            onClick={() => {
              navigate(
                `/encaminhamento/${encaminhamentoSelecionado.id}/#situacao`,
                {
                  state: { encaminhamentoSelecionado }
                }
              );
              // setExpandirProcedimentoSolicitado(true);
            }}
            style={{
              color: "inherit",
              textDecoration: "inherit",
              margin: "5px",
              padding: "1px"
            }}
          >
            Situação atual do paciente
          </a>
        </Card>
      </Grid>

      <Grid container spacing={2} style={{ margin: "15px" }}>
        <Container
          style={{ marginBottom: "2rem", boxShadow: "1px 1px 5px #353353" }}
        >
          <div id="avaliacao" />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3>Avaliação do Paciente</h3>
            </Grid>

            <Grid item xs={12}>
              <TextFieldGeneric
                label="Diagnóstico "
                value={encaminhamentoFisio.diagnostico}
                minRows={3}
                onChange={(value) => {
                  setEncaminhamentoFisio({
                    ...encaminhamentoFisio,
                    diagnostico: value
                  });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              Ex: ¹ Limitação na extensão de punho | ² Dor e inchaço | ³ Em uso
              de fraldo devido incontnência urinária
              <TextFieldGeneric
                label="Descrição do quadro ¹ , Sintomas ² e outras observações pertinentes ³ "
                value={encaminhamentoFisio.descricaoQuadro}
                minRows={5}
                onChange={(value) => {
                  setEncaminhamentoFisio({
                    ...encaminhamentoFisio,
                    descricaoQuadro: value
                  });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <RadioButonGroupGeneric
                labelGroup={"Início dos sintomas"}
                value={encaminhamentoFisio.inicioSintomasFisioterapia}
                options={INICIO_SINTOMAS}
                row
                setValue={(value) => {
                  setEncaminhamentoFisio({
                    ...encaminhamentoFisio,
                    inicioSintomasFisioterapia: value
                  });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <CheckboxGeneric
                label="Teve procedimento cirúrgico ?"
                checked={encaminhamentoFisio.teveProcedimentoCirurgico}
                onChange={(x) =>
                  setEncaminhamentoFisio({
                    ...encaminhamentoFisio,
                    teveProcedimentoCirurgico: x
                  })
                }
              />
            </Grid>

            {encaminhamentoFisio.teveProcedimentoCirurgico && (
              <>
                <Grid item xs={6}>
                  <TextFieldGeneric
                    label="Qual procedimento cirurgico?"
                    value={encaminhamentoFisio.procedimentoCirurgico}
                    minRows={3}
                    onChange={(value) => {
                      setEncaminhamentoFisio({
                        ...encaminhamentoFisio,
                        procedimentoCirurgico: value
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextFieldGeneric
                    label="Quando foi o procedimento cirurgico?"
                    value={encaminhamentoFisio.quandoOcorreuCirurgia}
                    minRows={3}
                    onChange={(value) => {
                      setEncaminhamentoFisio({
                        ...encaminhamentoFisio,
                        quandoOcorreuCirurgia: value
                      });
                    }}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <CheckboxGeneric
                label="Deixou de realizar alguma atividade de vida devido tal acometimento?"
                checked={encaminhamentoFisio.deixouRealizarAlgumaAtividade}
                onChange={(x) =>
                  setEncaminhamentoFisio({
                    ...encaminhamentoFisio,
                    deixouRealizarAlgumaAtividade: x
                  })
                }
              />
            </Grid>
            {encaminhamentoFisio.deixouRealizarAlgumaAtividade && (
              <>
                <Grid item xs={4}>
                  <CheckboxGeneric
                    label={
                      "AVD (ir ao banheiro, trocar de roupa, higiene pessoal, outras)"
                    }
                    checked={encaminhamentoFisio.avd}
                    onChange={(x) => {
                      setEncaminhamentoFisio({
                        ...encaminhamentoFisio,
                        avd: x
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <CheckboxGeneric
                    label={
                      "AIVD (dirigir, pegar transporte público, fazer compras realizar atividades domésticas, outros)"
                    }
                    checked={encaminhamentoFisio.aivd}
                    onChange={(x) => {
                      setEncaminhamentoFisio({
                        ...encaminhamentoFisio,
                        aivd: x
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextFieldGeneric
                    label="Outros"
                    value={encaminhamentoFisio.outrasAtividadesDeixouRealizar}
                    onChange={(value) => {
                      setEncaminhamentoFisio({
                        ...encaminhamentoFisio,
                        outrasAtividadesDeixouRealizar: value
                      });
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Container>

        <Container
          style={{ marginBottom: "2rem", boxShadow: "1px 1px 5px #353353" }}
        >
          <div id="situacao" />
          <Grid item xs={12}>
            <RadioButonGroupGeneric
              labelGroup={"Situação no mercado de trabalho"}
              value={encaminhamentoFisio.situacaoMercadoTrabalho}
              options={SITUACAO_MERCADO_TRABALHO}
              row
              setValue={(value) => {
                setEncaminhamentoFisio({
                  ...encaminhamentoFisio,
                  situacaoMercadoTrabalho: value
                });
                console.log("yyyy", encaminhamentoFisio);
              }}
            />
          </Grid>

          <Grid container spacing={2}>
            {encaminhamentoFisio.situacaoMercadoTrabalho !== "3" && (
              <Grid item xs={12} style={{ marginTop: "1%" }}>
                <TextFieldGeneric
                  label="Profissão"
                  value={encaminhamentoFisio.profissao}
                  onChange={(value) => {
                    setEncaminhamentoFisio({
                      ...encaminhamentoFisio,
                      profissao: value
                    });
                  }}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <span>Necessidade de uso de prótese, órtese ou adaptação</span>
            </Grid>

            <Grid item xs={2}>
              <CheckboxGeneric
                label={"Ortese"}
                checked={encaminhamentoFisio.ortese}
                onChange={(x) => {
                  setEncaminhamentoFisio({
                    ...encaminhamentoFisio,
                    ortese: x
                  });
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <CheckboxGeneric
                label={"Protese"}
                checked={encaminhamentoFisio.protese}
                onChange={(x) => {
                  setEncaminhamentoFisio({
                    ...encaminhamentoFisio,
                    protese: x
                  });
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <CheckboxGeneric
                label={"Adaptação"}
                checked={encaminhamentoFisio.adaptacao}
                onChange={(x) => {
                  setEncaminhamentoFisio({
                    ...encaminhamentoFisio,
                    adaptacao: x
                  });
                }}
              />
            </Grid>
            <Grid item xs={61} />

            <Grid item xs={2}>
              <CheckboxGeneric
                label={"Já está em uso"}
                checked={encaminhamentoFisio.jaEmUsoProtese}
                onChange={(x) => {
                  setEncaminhamentoFisio({
                    ...encaminhamentoFisio,
                    jaEmUsoProtese: x
                  });
                }}
              />
            </Grid>

            {encaminhamentoFisio.jaEmUsoProtese && (
              <Grid item xs={10}>
                <TextFieldGeneric
                  label="Qual já usa ?"
                  value={encaminhamentoFisio.qualEmUso}
                  onChange={(value) => {
                    setEncaminhamentoFisio({
                      ...encaminhamentoFisio,
                      qualEmUso: value
                    });
                  }}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <h3>
                Anexe cópias dos laudos dos exames complementares se houver
              </h3>
            </Grid>

            <Grid item xs={12}>
              <GenericDatePicker
                label="Data da solicitação"
                value={dayjs()}
                setValue={() => {}}
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldGeneric
                label="Local da solicitação"
                value={"CEMMAS"}
                disabled
                onChange={(value) => {}}
              />
            </Grid>

            <Grid item xs={12}>
              <TextFieldGeneric
                label="Profissional Solicitante"
                value={"DR. Roberto Silva Martins"}
                disabled
                onChange={(value) => {}}
              />
            </Grid>

            <Grid item xs={12} style={{ textAlign: "center" }}>
              <IconButton onClick={() => {}}>
                <Printer size={32} style={{ margin: "10px 10px 10px 0px" }} />
                Imprimir
              </IconButton>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default EncaminhamentoFisioterapiaPage;
const useStyles = makeStyles({
  centerTexto: {
    marginTop: "5%"
  }
});
