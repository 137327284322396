import { Container, Grid } from "@mui/material";

import { useEffect, useState } from "react";

import FormularioNovoEncaminhamento from "./FormularioNovoEncaminhamento";
import DropZone from "../../../Generics/DropZone";
import encaminhamentoApi from "../../../../api/encaminhamentoApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

type Props = {};
const NovoEncaminhamentoPage: React.FC<Props> = (props) => {
  const [file, setFile] = useState<File | null>(null);
  const [showEffectButton, setShowEffectButton] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (file) upload();
  }, [file]);

  const upload = async () => {
    try {
      if (file) await encaminhamentoApi.upload(file);
      toast.success(`Encaminhamento realizado`);
      navigate(`/`);
    } catch (erro: any) {
      toast.error(`Erro ao fazer importação do arquivo do PEC.`);
      console.log("erro ao importar fomulario PEC", erro);
    }
  };

  return (
    <Container>
      <Grid item xs={12}>
        <DropZone
          extensaoArquivo={["pdf"]}
          setFile={(value) => {
            setFile(value);
          }}
        />
      </Grid>

      <Grid item xs={12} style={{ textAlign: "center" }}>
        <h1>OU</h1>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <h5>preencha as informações manualmente</h5>
      </Grid>

      <Grid item xs={12}>
        <FormularioNovoEncaminhamento />
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "15px",
        }}
      />
    </Container>
  );
};

export default NovoEncaminhamentoPage;
