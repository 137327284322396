import { Grid, IconButton, LinearProgress } from "@mui/material";
import { makeStyles } from "@material-ui/core";

import Container from "../../../Generics/Container";
import logoFormiga from "../../../../images/brasao_formiga.png";
import { Paciente } from "../../../../model/entities/Paciente";

import { Printer } from "@phosphor-icons/react";
import { AtestadoMedico } from "../../../../model/entities/Atestado";
import NumberFieldGeneric from "../../../Generics/NumberFieldGeneric";

import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { useEffect, useState } from "react";
import TextFieldGeneric from "../../../Generics/TextFieldGeneric";
import { toast } from "react-toastify";
import atestadoApi from "../../../../api/atestadoApi";
import { useSessao } from "../../../../redux/sessao/sessaoHooks";
import CidsField from "../../../Generics/Fields/CidsField";
import ModalGeneric from "../../../Generics/ModalGeneric";

type Props = {
  paciente: Paciente;
  atestado: AtestadoMedico;
  setAtestado: (atestado: AtestadoMedico) => void;
  apenasVisualizacao?: boolean;
  nomePaciente?: string;
};

const AtestadoFormulario: React.FC<Props> = (props) => {
  const { paciente, atestado, setAtestado, apenasVisualizacao, nomePaciente } =
    props;

  const [loading, setLoading] = useState(false);
  const data = dayjs().locale("pt-br");
  const dia = data.format("DD");
  const mes = data.format("MMMM");
  const ano = data.format("YYYY");
  const [sessao] = useSessao();

  useEffect(() => {
    if (!apenasVisualizacao) {
      setAtestado({
        ...atestado,
        dataAtestado: dayjs().format("YYYY-MM-DD"),
        idMedico: sessao.usuario?.id ?? 0,
        idPaciente: paciente.id,
        idPontoAtendimento: 1,
      });
    }
  }, []);

  const imprimir = async () => {
    try {
      if (!atestado || !atestado.qntDias) return;
      setLoading(true);
      await atestadoApi.imprimir({
        cid: atestado.motivo,
        medico: "",
        paciente: nomePaciente ?? paciente.nome,
        qntDias: atestado.qntDias,
      });
      setLoading(false);
    } catch (erro: any) {
      console.log("erro", erro);
      toast.error("Erro ao imprimir atestado");
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <ModalGeneric open={loading} onClose={() => {}}>
        <h2 style={{ textAlign: "center" }}>Gerando atestado . . .</h2>
        <LinearProgress />
      </ModalGeneric>
    );
  }
  return (
    <Container page style={{ marginTop: "25px" }}>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <h1>Prefeitura Municipal de Formiga</h1>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <img
          src={logoFormiga}
          alt="Brasão Município Formiga-MG"
          style={{ maxWidth: "8%" }}
        />
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <h1 style={{ textAlign: "center" }}>Atestado Médico</h1>
        </Grid>

        <Grid item xs={4} style={{ marginTop: "12px" }}>
          <span>Atesto para o devidos fins, que o Sr.(a) </span>
        </Grid>

        <Grid item xs={8}>
          <TextFieldGeneric
            disabled={apenasVisualizacao}
            variant="standard"
            value={nomePaciente ?? paciente.nome}
            label=""
            onChange={() => {}}
          />
        </Grid>

        <Grid item xs={8} style={{ marginTop: "30px" }}>
          <span>
            está impossibilitado de erxercer as suas atividades profissionais
            durante
          </span>
        </Grid>

        <Grid item xs={3}>
          <NumberFieldGeneric
            variant="standard"
            value={atestado.qntDias}
            maxLength={3}
            label="Quantidade Dias"
            onChange={(value) => {
              setAtestado({ ...atestado, qntDias: value });
            }}
          />
        </Grid>

        <Grid item xs={1} style={{ marginTop: "30px" }}>
          <span>dia(s)</span>
        </Grid>

        <Grid item xs={6} style={{ marginTop: "30px" }}>
          a partir desta data, por motivo de doença. CID.
        </Grid>

        <Grid item xs={6}>
          <CidsField
            setCid={(value) =>
              setAtestado({
                ...atestado,
                motivo: `${value?.codigo} - ${value?.nome ?? ""}`,
              })
            }
            codigoCid={atestado.motivo}
          />
        </Grid>

        <Grid item xs={12} style={{ textAlign: "right", marginTop: "30px" }}>
          {!apenasVisualizacao && (
            <span>
              Formiga, {dia} de {mes} de {ano}
            </span>
          )}

          {apenasVisualizacao && (
            <span>
              Formiga, {dayjs(atestado.dataAtestado).format("DD")} de{" "}
              {dayjs(atestado.dataAtestado).locale("pt-br").format("MMMM")} de{" "}
              {dayjs(atestado.dataAtestado).format("YYYY")}
            </span>
          )}
        </Grid>
        <Grid item xs={7} style={{ marginTop: "5%" }}>
          {sessao.usuario?.nome}
          <hr />
          Medico
        </Grid>
        <Grid item xs={5} style={{ marginTop: "5%" }}>
          123456789
          <hr />
          CRM
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ marginTop: "10%", textAlign: "center" }}>
        <IconButton
          onClick={() => {
            imprimir();
          }}
        >
          <Printer size={32} style={{ margin: "10px" }} />
          Imprimir
        </IconButton>
      </Grid>
    </Container>
  );
};

export default AtestadoFormulario;
const useStyles = makeStyles({
  centerTexto: {
    marginTop: "5%",
  },
});
