import { Endereco } from "./Endereco";

export class Pessoa {
  nome!: string;

  dataNascimento!: string;

  genero!: string;

  email!: string;

  endereco!: Endereco;

  telefone!: string;

  cpf!: string;

  nomeMae!: string;
}
