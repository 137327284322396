import { Grid } from "@mui/material";
import { Encaminhamento } from "../../../../../../model/entities/Encaminhamento";
import { CheckFat, X } from "@phosphor-icons/react";
import { Paciente } from "../../../../../../model/entities/Paciente";
import TabelaEncaminhamentosEspecialistas from "./TabelaEncaminhamentosEspecialistas";
import { useState } from "react";
import ModalGeneric from "../../../../../Generics/ModalGeneric";
import DadosEncaminhamentoPage from "../../../Consulta/DadosEncaminhamentoPage";
import { ContraReferencia } from "../../../../../../model/entities/ContraReferencia";

type Props = {
  paciente: Paciente;
};

const EncaminhamentoEspecialistas: React.FC<Props> = (props) => {
  const { paciente } = props;

  const [encaminhamentoSelecionado, setEncaminhamentoSelecionado] =
    useState<Encaminhamento | null>(null);

  return (
    <Grid>
      <TabelaEncaminhamentosEspecialistas
        paciente={paciente}
        onRowClick={(value) => {
          setEncaminhamentoSelecionado(value);
        }}
      />
      {encaminhamentoSelecionado && (
        <ModalGeneric
          open={Boolean(encaminhamentoSelecionado)}
          onClose={() => setEncaminhamentoSelecionado(null)}
        >
          <DadosEncaminhamentoPage
            encaminhamento={encaminhamentoSelecionado}
            contraReferencia={
              encaminhamentoSelecionado.contraReferencia ??
              new ContraReferencia()
            }
            setContraReferencia={() => {}}
            apenasVisualizacao
          />
        </ModalGeneric>
      )}
    </Grid>
  );
};

export default EncaminhamentoEspecialistas;
