import { Paciente } from "../../../../model/entities/Paciente";

import { Container, Grid } from "@mui/material";
import TextFieldGeneric from "../../../Generics/TextFieldGeneric";

import GenericDatePicker from "../../../Generics/DatePickerGeneric";
import dayjs from "dayjs";
import { useState } from "react";
import buscaCepApi from "../../../../api/buscaCepApi";
import Loader from "../../../Generics/Loader";
import Enumerador from "../../../Generics/Enumerador";
type Props = {
  paciente: Paciente;
};

const TabPanelDadosPaciente: React.FC<Props> = (props) => {
  const { paciente } = props;

  const [cep, setCep] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [uf, setUf] = useState("");

  const [buscandoCep, setBuscandoCep] = useState(false);

  const buscarCep = async () => {
    try {
      setBuscandoCep(true);
      const res = await buscaCepApi.buscaCep("35570-280");
      setBairro(res.district);
      setCidade(res.city);
      setUf(res.state);
      setLogradouro(res.address);
    } catch (erro: any) {
      console.log("Erro ao buscar CEP", erro);
    } finally {
      setBuscandoCep(false);
    }
  };

  return (
    <Grid style={{ margin: "15px" }}>
      <Container
        style={{
          marginBottom: "2rem",
          boxShadow: "1px 1px 4px #353353"
        }}
      >
        <Grid container spacing={2}>
          <Enumerador numero={1} label="Dados básicos" />

          <Grid item xs={12}>
            <TextFieldGeneric
              label="Nome Paciente"
              value={paciente.nome}
              onChange={() => {}}
            />
          </Grid>

          <Grid item xs={6}>
            <GenericDatePicker
              label="Data de Nascimento"
              value={dayjs(paciente.dataNascimento, "YYYYMMDD")}
              setValue={() => {}}
            />
          </Grid>

          <Grid item xs={6}>
            <TextFieldGeneric
              label="Genero"
              value={paciente.genero}
              onChange={() => {}}
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              label="Email"
              value={paciente.email}
              onChange={() => {}}
            />
          </Grid>

          <Grid item xs={6}>
            <TextFieldGeneric
              label="CPF / CNS"
              value={paciente.cpf}
              onChange={() => {}}
            />
          </Grid>

          <Grid item xs={6}>
            <TextFieldGeneric
              label="Telefone"
              value={paciente.telefone}
              onChange={() => {}}
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              label="Nome Responsável"
              value={paciente.nomeResponsavell}
              onChange={() => {}}
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              label="Observação"
              value={paciente.observacao}
              onChange={() => {}}
            />
          </Grid>

          <Grid item xs={12} style={{ marginBottom: "1rem" }}>
            <TextFieldGeneric
              label="Nome mãe"
              value={paciente.nomeMae}
              onChange={() => {}}
            />
          </Grid>
        </Grid>
      </Container>

      <Container
        style={{ marginBottom: "2rem", boxShadow: "1px 1px 4px #353353" }}
      >
        <Grid container spacing={2}>
          <Enumerador numero={2} label="Endereço" />

          <Grid item xs={6}>
            <TextFieldGeneric
              label="CEP"
              value={cep}
              onChange={(value) => {
                if (value.length >= 8) {
                  buscarCep();
                }
                setCep(value);
              }}
            />
          </Grid>

          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              flexDirection: "row"
            }}
          >
            {buscandoCep && (
              <>
                <Loader size={36} />
                <span style={{ marginLeft: "10px", marginTop: "5px" }}>
                  {" "}
                  Buscando CEP
                </span>
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              value={logradouro}
              label="Logradouro"
              onChange={(value) => {
                setLogradouro(value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              value={bairro}
              label="Bairro"
              onChange={(value) => {
                setBairro(value);
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextFieldGeneric
              value={cidade}
              label="Cidade"
              onChange={(value) => {
                setCidade(value);
              }}
            />
          </Grid>

          <Grid item xs={6} style={{ marginBottom: "1rem" }}>
            <TextFieldGeneric
              value={uf}
              label="UF"
              onChange={(value) => {
                setUf(value);
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default TabPanelDadosPaciente;
