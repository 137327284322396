import { RouteType } from "../config";
import grupoConfiguracoes from "./grupoConfiguracoes";
import grupoEncaminhamentos from "./grupoEncaminhamentos";
import grupoInicio from "./grupoInicio";
import grupoPacientes from "./grupoPacientes";

const rotas: RouteType[] = [
  grupoInicio,
  grupoPacientes,
  grupoEncaminhamentos,
  grupoConfiguracoes,
];

export default rotas;
