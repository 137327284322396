import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Alert, Grid } from "@mui/material";
import { Encaminhamento } from "../../../../../../model/entities/Encaminhamento";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import receitaApi from "../../../../../../api/receitaApi";
import { Paciente } from "../../../../../../model/entities/Paciente";
import { CheckFat, Info, X } from "@phosphor-icons/react";

import encaminhamentoApi, {
  statusEncaminhamento,
} from "../../../../../../api/encaminhamentoApi";
import InfoTabela from "../../../../../Generics/InfoTabela";

const columns: Encaminhamento[] | any[] = [
  {
    field: "unidadeSolicitante",
    headerName: "P.A. Solicitante",
    width: 350,
    editable: false,
  },
  {
    field: "profissionalSolicitante",
    headerName: "Prof. Solicitante",
    width: 350,
    editable: false,
  },

  {
    field: "motivoEncaminhamento",
    headerName: "Motivo do encaminhamento",
    width: 250,
    editable: false,
  },
  {
    field: "hipotese",
    headerName: "Hipótese / Diagnóstico (CID10)",
    width: 250,
    editable: false,
  },

  {
    field: "dataEncaminhamento",
    headerName: "Data do encaminhamento",
    width: 150,
    editable: false,
    renderCell: (params: Encaminhamento) => {
      return dayjs(params.dataEncaminhamento).format("DD/MM/YYYY");
    },
  },
  {
    field: "Status",
    headerName: "Status",
    width: 100,
    renderCell: (params: any) => {
      return statusEncaminhamento(params.row as Encaminhamento);
    },
  },
];

type Props = {
  paciente: Paciente;
  onRowClick: (receita: Encaminhamento) => void;
};

const TabelaEncaminhamentosEspecialistas: React.FC<Props> = (props) => {
  const { onRowClick, paciente } = props;

  const [encaminhamentos, setEncaminhamentos] = useState<Encaminhamento[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    buscarDadosPaciente();
  }, []);

  const buscarDadosPaciente = async () => {
    try {
      setLoading(true);
      const resEncaminhamentos = await encaminhamentoApi.getByPaciente(
        paciente.id
      );

      setEncaminhamentos(resEncaminhamentos);
      setLoading(false);
    } catch (erro: any) {
      setLoading(false);

      toast.error(`Erro ao buscar informações do paciente`);
    }
  };

  return (
    <Grid container xs={12} sm={12} spacing={2}>
      <InfoTabela mensagem="Para mais detalhes clique no encaminhamento" />
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <DataGrid
          columns={columns}
          rows={encaminhamentos}
          loading={loading}
          rowCount={encaminhamentos.length}
          pagination={true}
          onRowClick={(params) => {
            onRowClick(params.row as Encaminhamento);
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pageSizeOptions={[5, 10, 25]}
          style={{
            height: 400,
            textAlign: "center",
            border: "1px solid #ddd",
            boxShadow: "1px 1px 5px #353353",
            borderRadius: "10px",
            padding: "10px",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default TabelaEncaminhamentosEspecialistas;
