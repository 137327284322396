import { useEffect, useRef, useState } from "react";

import api from "./api/api";

import moment from "moment";

export function makeRandomString(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const SM_SCREEN = 640;
export const MD_SCREEN = 768;
export const LG_SCREEN = 1024;
export const XL_SCREEN = 1280;

export const formatarDinheiro = (value: number | string) => {
  if (!value) {
    return "0,00";
  }

  if (typeof value === "string") {
    value = parseFloat(value);
  }

  value = value.toFixed(2);
  value = value.replace(".", ",");
  if (value.length > 6) {
    value = value.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
  }
  return value;
};

/*
 * Recebe uma data YYYYMMDD e retorna DD/MM/YYYY
 */
export const formatarData = (data: number | string | undefined) => {
  if (!data) {
    return "";
  }
  if (typeof data === "number") {
    data = data.toString();
  }
  data = data.replace(/-/g, "");
  data = data.slice(6, 8) + "/" + data.slice(4, 6) + "/" + data.slice(0, 4);
  return data;
};

export const formatarHora = (hora: number | string) => {
  if (!hora) {
    return "0";
  }
  if (typeof hora === "number") {
    hora = hora.toString();
  }
  hora = hora.slice(0, 2) + ":" + hora.slice(2, 4);
  return hora;
};

export const cpfMask = (value: number | string) => {
  if (!value) {
    return "";
  }
  if (typeof value !== "string") {
    value = value.toString();
  }
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export const cnpjMask = (value: number | string) => {
  if (!value) {
    return "";
  }
  if (typeof value !== "string") {
    value = value.toString();
  }
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};

export function arredondar(valor: number, casasDecimais: 2) {
  const valorArredondado =
    Math.round(valor * Math.pow(10, casasDecimais)) /
    Math.pow(10, casasDecimais);
  return valorArredondado;
}

export const hexWithAlpha = (hexCode: string, opacity: number) => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity})`;
};

export function mudarIluminacao(hex: string, lum: number) {
  // lum > 0 == mais claro
  // lum < 0 == mais escuro

  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 3) {
    return "#000";
  }
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  let rgb = "#",
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }

  return rgb;
}

// Hook
export function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, (obj: T) => void] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error: any) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error: any) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export function usePageStorage<T>(
  page: string,
  key: string,
  initialValue: T
): [T, (obj: T) => void] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const pageStr = window.localStorage.getItem(page);
      const pageParsed = pageStr ? JSON.parse(pageStr) : {};

      const item = pageParsed[key];

      return item ? item : initialValue;
    } catch (error: any) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  const setValue = (value: T) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);

      // Save to local storage
      const pageStr = window.localStorage.getItem(page);
      const pageParsed = pageStr ? JSON.parse(pageStr) : {};
      pageParsed[key] = valueToStore;
      window.localStorage.setItem(page, JSON.stringify(pageParsed));
    } catch (error: any) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

export function getItemPageStorage<T>(page: string, key: string): T {
  const pageStr = window.localStorage.getItem(page);
  const pageParsed = pageStr ? JSON.parse(pageStr) : {};
  const item = pageParsed[key];
  return item;
}

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match

  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

/**
 * Funciona da mesma forma que o useEffect, mas não
 * é chamado na renderização inicial, apenas nos updates.
 *
 * @date 2020-12-31
 * @param {any} effect:React.EffectCallback
 * @param {any} deps?:React.DependencyList|undefined
 * @returns {any}
 */
export const useUpdateEffect = (
  effect: React.EffectCallback,
  deps?: React.DependencyList | undefined
) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      effect();
    }
  }, deps);
};

export function isObjectEmpty(obj: any) {
  return obj && Object.keys(obj).length === 0;
}

export const blobToBase64 = (blob: Blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise<string>((resolve, reject) => {
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
    reader.onerror = (err) => {
      reject(err);
    };
  });
};

// 0 -> versões iguais
// 1 -> v1 é maior
// 2 -> v2 é maior
export function compareVersions(v1: string, v2: string) {
  if (!v1 || !v2) {
    return 0;
  }

  const arrayV1 = v1.split(".");
  const arrayV2 = v2.split(".");

  if (arrayV1.length > arrayV2.length) {
    const diferenca = arrayV1.length - arrayV2.length;
    for (let i = 0; i < diferenca; i++) {
      arrayV2.push("0");
    }
  } else if (arrayV2.length > arrayV1.length) {
    const diferenca = arrayV2.length - arrayV1.length;
    for (let i = 0; i < diferenca; i++) {
      arrayV1.push("0");
    }
  }

  for (let i = 0; i < arrayV1.length; i++) {
    const el_v1 = parseInt(arrayV1[i]);
    const el_v2 = parseInt(arrayV2[i]);
    if (el_v1 > el_v2) {
      return 1;
    } else if (el_v2 > el_v1) {
      return 2;
    }
  }
  return 0;
}

export async function scaleImage(file: File, max_size: 1000) {
  return new Promise<File>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (readerEvent) {
      if (!readerEvent || !readerEvent.target) {
        return;
      }
      const image = new Image();
      image.onload = function (imageEvent) {
        // Resize the image
        const canvas = document.createElement("canvas");

        let width = image.width;
        let height = image.height;
        if (width > height) {
          if (width > max_size) {
            height *= max_size / width;
            width = max_size;
          }
        } else {
          if (height > max_size) {
            width *= max_size / height;
            height = max_size;
          }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d")?.drawImage(image, 0, 0, width, height);
        const resizedImage = canvas.toDataURL("image/jpeg");

        canvas.toBlob(async (blob) => {
          if (!blob) {
            throw new Error("Erro ao diminuir tamanho da imagem.");
          }
          const newFile = new File([blob], file.name, { type: "image/jpeg" });
          resolve(newFile);
        }, "image/jpeg");
      };

      image.src = readerEvent.target.result as string;
    };
    reader.readAsDataURL(file);
  });
}

export const fileToBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const res = reader.result;
      if (typeof res === "string") {
        resolve(res);
      } else {
        reject(new Error("Unknown error."));
      }
    };
    reader.onerror = (error) => reject(error);
  });

// export const base64ToFile = (base64: string) =>
//   new Promise<File>(async (resolve, reject) => {
//     const res = await fetch(base64);
//     const blob = await res.blob();
//     const file = new File([blob], "localfile", { type: "image/png" });
//     resolve(file);
//   });

export function calcularDiferencaEmDiasDuasData(
  dataUltimaAtualizacao: string,
  dataAtual: string
) {
  const dueDate = moment(dataUltimaAtualizacao, "YYYYMMDD");
  const currentDate = moment(dataAtual, "YYYYMMDD");
  return currentDate.diff(dueDate, "days");
}

export function lerArquivo(file: File) {
  return new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload = function (evt) {
        resolve(evt?.target?.result);
      };
      reader.onerror = function (evt) {
        reject(new Error("Erro ao ler arquivo."));
      };
    } else {
      throw new Error("Nenhum arquivo selecionado.");
    }
  });
}

// function useQuery() {
//   const { search } = useLocation();
//   return React.useMemo(() => new URLSearchParams(search), [search]);
// }
