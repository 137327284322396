import { RouteType } from "../config";
import DashboardPageLayout from "../../components/layout/DashboardPageLayout";
import EncaminhamentosPage from "../../components/App/Pages/Encaminhamento/EncaminhamentosPage";
import ConsultaPage from "../../components/App/Pages/Consulta/ConsultaPage";
import NovoEncaminhamentoPage from "../../components/App/Pages/Encaminhamento/NovoEncaminhamentoPage";

import { Usuario } from "../../model/entities/Usuario";
import SettingsIcon from "@mui/icons-material/Settings";
import ConfiguracaoGeralPage from "../../components/App/Pages/Configurações/Gerais/ConfiguracaoGeralPage";
import ConfiguracoesUsuariosPage from "../../components/App/Pages/Configurações/Usuarios/ConfiguracoesUsuariosPage";
import ConfiguracoesPerfilPage from "../../components/App/Pages/Configurações/Perfil/ConfiguracoesPerfilPage";
import ConfiguracoesPontoAtendimentoPage from "../../components/App/Pages/Configurações/PontosAtendimentos/ConfiguracoesPontoAtendimentoPage";
import GridCenter from "../../components/Generics/GridCenter";

const grupoConfiguracoes: RouteType = {
  path: "/configuracoes",
  element: <DashboardPageLayout />,
  state: "configuracoes",
  verificarPermissao: (usuario: Usuario | null) => {
    if (usuario && usuario.perfil.id === 1) {
      return true;
    }
    return false;
  },

  sidebarProps: {
    displayText: "Configurações",
    icon: <SettingsIcon />,
  },

  child: [
    {
      index: true,
      element: <ConfiguracaoGeralPage />,
      state: "configuracoes.index",
    },

    {
      path: "/configuracoes/pontoAtendimento",
      element: (
        <GridCenter style={{ justifyContent: "center" }}>
          <ConfiguracoesPontoAtendimentoPage />
        </GridCenter>
      ),
      state: "configuracoes.pontoAtendimento",
      sidebarProps: {
        displayText: "Pontos de atendimentos",
      },
    },
    {
      path: "/configuracoes/usuarios",
      element: (
        <GridCenter style={{ justifyContent: "center" }}>
          <ConfiguracoesUsuariosPage />
        </GridCenter>
      ),
      state: "configuracoes.usuarios",
      sidebarProps: {
        displayText: "Usuários do sistema",
      },
    },

    {
      path: "/configuracoes/perfil",
      element: (
        <GridCenter style={{ justifyContent: "center" }}>
          <ConfiguracoesPerfilPage />
        </GridCenter>
      ),
      state: "configuracoes.perfil",
      sidebarProps: {
        displayText: "Perfis de usuário",
      },
    },

    {
      path: "/configuracoes/:id",
      element: <ConsultaPage />,
      state: "configuracoes.id",
    },
  ],
};

export default grupoConfiguracoes;
