import * as React from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/material";
import { LISTA_SEXOS } from "../../../constants";

type Props = {
  sx: number;
  setSexo: (p: string) => void;
};

const SexoField: React.FC<Props> = (props) => {
  const { sx, setSexo } = props;

  return (
    <div style={{ textAlign: "center" }}>
      <Autocomplete
        id="filter-sexo"
        options={LISTA_SEXOS}
        getOptionLabel={(option) => option.decricao}
        onChange={(event, value) => {
          if (value) setSexo(value.decricao);
        }}
        sx={{ width: sx }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={"outlined"}
            label={"Gênero"}
            placeholder={"Gênero"}
            multiline={true}
          />
        )}
      />
    </div>
  );
};

export default SexoField;
