import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

type Props = {
  labelGroup: string;
  options: { label: string; value: string }[];
  row?: boolean;
  value: string;
  setValue: (x: string) => void;
};

const RadioButonGroupGeneric: React.FC<Props> = (props) => {
  const { options, labelGroup, row, value, setValue } = props;

  return (
    <FormControl>
      <FormLabel>{labelGroup}</FormLabel>
      <RadioGroup
        row={row}
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
      >
        {options.map((option, index) => {
          return (
            <FormControlLabel
              value={option.value}
              control={<Radio />}
              label={option.label}
              key={index + 1}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButonGroupGeneric;
