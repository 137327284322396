import * as React from "react";
// eslint-disable-next-line import/named
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Menu, MenuItem } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import { Icon, styled } from "@mui/material";

import { Pulse } from "@phosphor-icons/react";
import { colors, drawerWidth } from "../../theme";
import { useSessao } from "../../redux/sessao/sessaoHooks";

type Props = {
  drawerOpen: boolean;
  onDrawerToggle: () => void;
};
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header: React.FC<Props> = (props) => {
  const { drawerOpen, onDrawerToggle } = props;

  const [ancora, setAncora] = React.useState<null | HTMLElement>(null);
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  const [sessao, login, revalidate, logout, trocaToken] = useSessao();

  return (
    <AppBar position="fixed" sx={{ backgroundColor: colors.primary }}>
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" noWrap component="div">
          <Icon>
            <Pulse size={36} />
          </Icon>{" "}
          Sisus - Sistema integrado do SUS
        </Typography>

        <div>
          <Menu
            id="simple-menu"
            anchorEl={ancora}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            keepMounted
            open={menuIsOpen}
            onClose={() => setMenuIsOpen(false)}
          >
            <MenuItem
              onClick={() => {
                logout();
              }}
            >
              Sair
            </MenuItem>
          </Menu>

          <IconButton
            ref={(ref) => {
              if (ref !== null && ancora === null) {
                setAncora(ref);
              }
            }}
            color="inherit"
            onClick={() => setMenuIsOpen(true)}
          >
            <Avatar>{sessao.usuario?.nome[0].toUpperCase() ?? ""}</Avatar>
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
