import { Alert, Grid } from "@mui/material";

type Props = {
  mensagem: string;
};

const InfoTabela: React.FC<Props> = (props) => {
  const { mensagem } = props;

  return (
    <Grid container xs={12} sm={12} spacing={2}>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Grid item />
        <div style={{ display: "flex", flexDirection: "row", right: "0" }}>
          <Alert elevation={6} variant="standard" severity="info">
            {mensagem}
          </Alert>
        </div>
      </Grid>
    </Grid>
  );
};

export default InfoTabela;
