import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SwipeableViews from "react-swipeable-views";
import Atestado from "./AtestadoFormulario";
import Receituario from "./Receituario";
import FomularioSolicitacaoInternacao from "./FomularioSolicitacaoInternacao";
import EncaminhamentoPage from "./DadosEncaminhamentoPage";
import { useLocation, useNavigate } from "react-router-dom";
import { Encaminhamento } from "../../../../model/entities/Encaminhamento";
import Grid from "@material-ui/core/Grid/Grid";
import { Button, Paper } from "@mui/material";
import { FloppyDisk } from "@phosphor-icons/react";
import { ContraReferencia } from "../../../../model/entities/ContraReferencia";
import { useState } from "react";
import { Receita } from "../../../../model/entities/Receita";
import { AtestadoMedico } from "../../../../model/entities/Atestado";
import { EncaminhamentoFisioterapia } from "../../../../model/entities/EncaminhamentoFisioterapia";
import EncaminhamentoFisioterapiaPage from "./EncaminhamentoFisioterapiaPage";
import { toast } from "react-toastify";
import atestadoApi from "../../../../api/atestadoApi";
import receitaApi from "../../../../api/receitaApi";
import encaminhamentoFisioApi from "../../../../api/encaminhamentoFisioApi";
import { SolicitacaoInternacao } from "../../../../model/entities/SolicitacaoInternacao";
import solicitacaoInternacaoApi from "../../../../api/solicitacaoInternacaoApi";
import ProcedimentoAltoCustoPage from "./ProcedimentoAltoCustoPage";
import { Paciente } from "../../../../model/entities/Paciente";
import encaminhamentoApi from "../../../../api/encaminhamentoApi";
import { useSessao } from "../../../../redux/sessao/sessaoHooks";
import { colors } from "../../../../theme";
interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function ConsultaPage() {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const { state } = useLocation();
  const encaminhamento = state.encaminhamentoSelecionado as Encaminhamento;

  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const theme = useTheme();
  const [sessao] = useSessao();

  const [contraReferencia, setContraReferencia] = useState<ContraReferencia>(
    encaminhamento.contraReferencia ?? new ContraReferencia()
  );

  const [atestado, setAtestado] = useState<AtestadoMedico>(
    new AtestadoMedico()
  );
  const [receita, setReceita] = useState<Receita>(new Receita());

  const [encaminhamentoFisioterapia, setEncaminhamentoFisioterapia] =
    useState<EncaminhamentoFisioterapia>(new EncaminhamentoFisioterapia());

  const [solicitacaoInternacao, setSolicitacaoInternacao] =
    useState<SolicitacaoInternacao>(new SolicitacaoInternacao());

  const salvar = async () => {
    try {
      if (!contraReferencia.diagnostico) {
        toast.error("É preciso preencher o diagnostico na contra referência!");
        handleChangeIndex(0);
        return;
      }

      if (!contraReferencia.parecer) {
        toast.error("É preciso preencher o parecer na contra referência!");
        handleChangeIndex(0);
        return;
      }

      if (contraReferencia.diagnostico) {
        await encaminhamentoApi.update(encaminhamento.id, {
          pacienteAusente: false,
          dadosAtualizarContraReferencia: {
            data: contraReferencia.data,
            diagnostico: contraReferencia.diagnostico,
            observacao: contraReferencia.observacao,
            parecer: contraReferencia.parecer,
            pontoAtendimentoId: contraReferencia.pontoAtendimento.id,
            profissional: sessao.usuario?.login ?? "",
          },
        });
      }

      if (atestado.qntDias > 0) {
        await atestadoApi.insert(atestado);
      }

      if (receita.prescricao) {
        await receitaApi.insert(receita);
      }

      if (
        encaminhamentoFisioterapia.diagnostico &&
        encaminhamentoFisioterapia.descricaoQuadro
      ) {
        await encaminhamentoFisioApi.insert(encaminhamentoFisioterapia);
      }

      if (
        solicitacaoInternacao.principaisSintomas &&
        solicitacaoInternacao.diagnosticoInicail
      ) {
        await solicitacaoInternacaoApi.insert(solicitacaoInternacao);
      }
      toast.success("Atendimento concluído!");
      navigate("/encaminhamentos");
    } catch (erro: any) {
      toast.error(
        "Um erro inesperado ocorreu. Nossa equipe técnica foi notificada."
      );
    }
  };

  return (
    <Box marginX={"1%"}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        aria-label="full width tabs example"
      >
        <Tab label="Encaminhamento - Referência" {...a11yProps(0)} />

        {sessao.usuario?.perfil.podePreescreverAtestado && (
          <Tab label="Atestado Médico" {...a11yProps(1)} />
        )}

        {sessao.usuario?.perfil.podePreescreverReceita && (
          <Tab label="Receituario" {...a11yProps(2)} />
        )}
        {/* <Tab label="Solicitação de Internação" {...a11yProps(3)} />
        <Tab label="Encaminhamento Fisioterapia" {...a11yProps(4)} />
        <Tab label="Procedimentos de alto custo" {...a11yProps(5)} /> */}
      </Tabs>

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <EncaminhamentoPage
              encaminhamento={encaminhamento}
              contraReferencia={contraReferencia}
              setContraReferencia={(c) => setContraReferencia(c)}
            />
          </div>
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Atestado
              nomePaciente={encaminhamento.nomeCidadao}
              paciente={encaminhamento.paciente ?? new Paciente()}
              atestado={atestado}
              setAtestado={(a) => setAtestado(a)}
            />
          </div>
        </TabPanel>

        <TabPanel value={value} index={2} dir={theme.direction}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Receituario
              paciente={encaminhamento.paciente ?? new Paciente()}
              receita={receita}
              setReceita={(r) => setReceita(r)}
            />
          </div>
        </TabPanel>

        <TabPanel value={value} index={3} dir={theme.direction}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FomularioSolicitacaoInternacao
              paciente={encaminhamento.paciente ?? new Paciente()}
              encaminhamentoSelecionado={encaminhamento}
              solicitacaoInternacao={solicitacaoInternacao}
              setSolicitacaoInternacao={(sl) => setSolicitacaoInternacao(sl)}
            />
          </div>
        </TabPanel>

        <TabPanel value={value} index={4} dir={theme.direction}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <EncaminhamentoFisioterapiaPage
              paciente={encaminhamento.paciente ?? new Paciente()}
              encaminhamentoFisio={encaminhamentoFisioterapia}
              setEncaminhamentoFisio={(e) => setEncaminhamentoFisioterapia(e)}
              encaminhamentoSelecionado={encaminhamento}
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ProcedimentoAltoCustoPage
              paciente={encaminhamento.paciente ?? new Paciente()}
              encaminhamentoFisio={encaminhamentoFisioterapia}
              setEncaminhamentoFisio={(e) => setEncaminhamentoFisioterapia(e)}
              encaminhamentoSelecionado={encaminhamento}
            />
          </div>
        </TabPanel>
      </SwipeableViews>

      <Grid item xs={12} style={{ textAlign: "center", marginBottom: "15px" }}>
        <Button
          startIcon={<FloppyDisk size={32} />}
          onClick={() => salvar()}
          variant="contained"
        >
          Salvar
        </Button>
      </Grid>
    </Box>
  );
}
