import { useEffect, useState } from "react";
import { Button, Container, Grid, IconButton } from "@mui/material";

import { ToastContainer, toast } from "react-toastify";

import { CheckFat, PaperPlaneRight, X } from "@phosphor-icons/react";
import { useNavigate } from "react-router";

import dayjs from "dayjs";
import { DataGrid } from "@mui/x-data-grid/DataGrid";

type Props = {};

const ConfiguracaoGeralPage: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  return (
    <Grid container xs={12} sm={12} spacing={2}>
      <h1>Sisus</h1>
    </Grid>
  );
};

export default ConfiguracaoGeralPage;
