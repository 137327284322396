import InfoTabela from "../../../Generics/InfoTabela";
import TabelaPacientes from "./TabelaPacientes";

type Props = {};

const PacientesPage: React.FC<Props> = (props) => {
  return (
    <>
      <TabelaPacientes />
    </>
  );
};

export default PacientesPage;
