import * as React from "react";
import TextField from "@mui/material/TextField";
import { Perfil } from "../../../model/entities/Perfil";
import { useEffect, useState } from "react";
import { Autocomplete } from "@mui/material";
import perfilApi from "../../../api/perfilApi";
import theme from "../../../theme";
import LISTA_CIDS from "../../../tabelaCid.json";

type Props = {
  setCid: (p: { codigo: string; nome: string } | null) => void;
  codigoCid: string | null;
};

const CidsField: React.FC<Props> = (props) => {
  const { codigoCid, setCid } = props;

  const [cidSelecionado, setCidSelecionado] = useState<{
    codigo: string;
    nome: string;
  } | null>(null);
  const xsValue = theme.breakpoints.down("sm") ? 12 : 6;

  useEffect(() => {
    if (codigoCid) {
      const cidEncontrado = LISTA_CIDS.find(
        (item) => item.codigo === codigoCid
      );

      if (cidEncontrado) setCidSelecionado(cidEncontrado);
    }
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <Autocomplete
        id="filter-perfis"
        options={LISTA_CIDS}
        value={cidSelecionado}
        getOptionLabel={(option) => `${option.codigo}-${option.nome}`}
        onChange={(event, value) => {
          if (value) setCid(value);
          setCidSelecionado(value);
        }}
        sx={{ xsValue }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={"outlined"}
            label={"CID10"}
            placeholder={"CID10"}
            multiline={true}
          />
        )}
      />
    </div>
  );
};

export default CidsField;
