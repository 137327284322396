import { PontoAtendimento } from "./PontoAtendimento";

export class AtestadoMedico {
  id!: number;
  idMedico!: number;
  idPaciente!: number;
  dataAtestado!: string;
  qntDias!: number;
  motivo!: string;
  idPontoAtendimento!: number;
  pontoAtendimento!: PontoAtendimento;
  nomePaciente?: string;
}
