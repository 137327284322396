import { Container, Grid, IconButton, Typography } from "@mui/material";

import CircleIcon from "@mui/icons-material/Circle";

type Props = {
  numero: number;
  label: string;
};

const Enumerador: React.FC<Props> = (props) => {
  const { numero, label } = props;
  return (
    <Grid item xs={12}>
      <Grid item xs={1}>
        <Typography
          style={{
            display: "block",
            borderRadius: "50%",
            width: "1.7em",
            height: "1.7em",
            textAlign: "center",
            float: "left",
            color: "white",
            backgroundColor: "#383838",
            border: "2px solid",
            marginRight: "10px"
          }}
          variant="h6"
          component="div"
          color={"black"}
        >
          {numero}
        </Typography>
      </Grid>

      <Grid item xs={11}>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
          color={"black"}
        >
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Enumerador;
