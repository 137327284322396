import * as React from "react";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { Autocomplete } from "@mui/material";
import { LISTA_TIPOS_PONTO_ATENDIMENTO } from "../../constants";
import theme from "../../theme";

type Props = {
  tipo: string | null;
  setTipo: (tp: typeof LISTA_TIPOS_PONTO_ATENDIMENTO[0] | null) => void;
  error?: boolean;
  helperText?: string;
};

const TipoPontoAtendimentoField: React.FC<Props> = (props) => {
  const { tipo, setTipo, error, helperText } = props;

  const [tipoPontAtendimento, setTipoPontAtendimento] = useState<
    typeof LISTA_TIPOS_PONTO_ATENDIMENTO[0] | null
  >(LISTA_TIPOS_PONTO_ATENDIMENTO[2]);

  useEffect(() => {
    const tipoEncontrado = LISTA_TIPOS_PONTO_ATENDIMENTO.find(
      (item) => item.value === tipo
    );

    if (tipoEncontrado) {
      setTipoPontAtendimento(tipoEncontrado);
      setTipo(tipoEncontrado);
    }
  }, [tipo]);

  const xsValue = theme.breakpoints.down("sm") ? 12 : 6;

  return (
    <div style={{ textAlign: "center" }}>
      <Autocomplete
        id="filter-demo"
        options={LISTA_TIPOS_PONTO_ATENDIMENTO}
        getOptionLabel={(option) => option.descricao}
        value={tipoPontAtendimento}
        onChange={(obj, value) => {
          setTipo(value);
          setTipoPontAtendimento(value);
        }}
        sx={{ xsValue }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            helperText={helperText}
            variant={"outlined"}
            label={"Tipo do ponto de atendimento"}
            placeholder={"Tipo do ponto de atendimento"}
            multiline={true}
          />
        )}
      />
    </div>
  );
};

export default TipoPontoAtendimentoField;
