import { Perfil } from "./Perfil";
import { PontoAtendimento } from "./PontoAtendimento";

export class Usuario {
  id!: number;
  login!: string;
  nome!: string;
  senha!: string;
  perfil!: Perfil;
  pontoAtendimento!: PontoAtendimento;
}
