import { Grid } from "@mui/material";
import { SolicitacaoInternacao } from "../../../../../../model/entities/SolicitacaoInternacao";
import { Paciente } from "../../../../../../model/entities/Paciente";
import { useState } from "react";
import ModalGeneric from "../../../../../Generics/ModalGeneric";

import TabelaSolicitacoesInternacoes from "./TabelaSolicitacoesInternacoes";
import FomularioSolicitacaoInternacao from "../../../Consulta/FomularioSolicitacaoInternacao";
import { Encaminhamento } from "../../../../../../model/entities/Encaminhamento";

type Props = {
  paciente: Paciente;
};

const SolicitacoesInternacaoPage: React.FC<Props> = (props) => {
  const { paciente } = props;

  const [solicitacaoSelecionada, setSolicitacaoSelecionada] =
    useState<SolicitacaoInternacao | null>(null);

  return (
    <Grid>
      <TabelaSolicitacoesInternacoes
        paciente={paciente}
        onRowClick={(value) => {
          // setSolicitacaoSelecionada(value);
        }}
      />
      {solicitacaoSelecionada && (
        <ModalGeneric
          open={Boolean(solicitacaoSelecionada)}
          onClose={() => setSolicitacaoSelecionada(null)}
        >
          <FomularioSolicitacaoInternacao
            paciente={new Paciente()}
            encaminhamentoSelecionado={new Encaminhamento()}
            solicitacaoInternacao={new SolicitacaoInternacao()}
            setSolicitacaoInternacao={function (
              sl: SolicitacaoInternacao
            ): void {
              throw new Error("Function not implemented.");
            }}
          />
        </ModalGeneric>
      )}
    </Grid>
  );
};

export default SolicitacoesInternacaoPage;
