import { Checkbox, FormControlLabel } from "@material-ui/core";
import React from "react";

type Props = {
  checked: boolean;
  onChange: (x: boolean) => void;
  label?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
};

const CheckboxGeneric: React.FC<Props> = (props) => {
  const { label, checked, onChange, style, ...other } = props;
  if (label) {
    return (
      <FormControlLabel
        style={{ width: "100%", height: "100%", ...style }}
        control={
          <Checkbox
            checked={checked}
            onChange={() => onChange(!checked)}
            // value="checkbox"
            // color="primary"
            // inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        }
        label={label}
        {...other}
      />
    );
  }

  return (
    <Checkbox
      checked={checked}
      onChange={() => onChange(!checked)}
      // value="checkbox"
      // color="primary"
      // inputProps={{ 'aria-label': 'secondary checkbox' }}
    />
  );
};

export default CheckboxGeneric;
