import { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

type Props = {
  label: string;
  value: Dayjs;
  setValue: (value: Dayjs) => void;
};

export default function DatePickerGeneric(props: Props) {
  const { value, setValue, label } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          label={label}
          format="DD/MM/YYYY"
          value={value}
          onChange={(newValue) => {
            if (newValue) setValue(newValue);
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
