import { Grid } from "@mui/material";

import { useState } from "react";
import ModalGeneric from "../../../../../Generics/ModalGeneric";

import { Paciente } from "../../../../../../model/entities/Paciente";
import { AtestadoMedico } from "../../../../../../model/entities/Atestado";
import TabelaAtestadoMedicos from "./TabelaAtestados";

import AtestadoFormulario from "../../../Consulta/AtestadoFormulario";

type Props = {
  paciente: Paciente;
};

const AtestadosProntuario: React.FC<Props> = (props) => {
  const { paciente } = props;

  const [atestadoSelecionado, setAtestadoSelecionado] =
    useState<AtestadoMedico | null>(null);

  return (
    <Grid>
      <Grid container spacing={2} style={{ margin: "15px 5px 0px 5px" }}>
        <TabelaAtestadoMedicos
          paciente={paciente}
          onRowClick={(value) => {
            setAtestadoSelecionado(value);
          }}
        />

        {atestadoSelecionado && (
          <ModalGeneric
            open={Boolean(atestadoSelecionado)}
            onClose={() => setAtestadoSelecionado(null)}
          >
            <AtestadoFormulario
              atestado={atestadoSelecionado}
              paciente={new Paciente()}
              nomePaciente={atestadoSelecionado.nomePaciente}
              setAtestado={() => {}}
              apenasVisualizacao
            />
          </ModalGeneric>
        )}
      </Grid>
    </Grid>
  );
};

export default AtestadosProntuario;
