import { SolicitacaoInternacao } from "../model/entities/SolicitacaoInternacao";
import { ResponseGenericType } from "../model/types/ResponseType";
import { FindManyReq } from "../model/types/TypeormTypes";
import api from "./api";

const PREFIX = "";
const ROUTE = "solicitacaoInternacao";

class solicitacaoInternacaoApi {
  async getMany() {
    try {
      const res = await api.get<ResponseGenericType<SolicitacaoInternacao[]>>(
        `/${ROUTE}`
      );
      const data = res.data.content;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getByPaciente(idPaciente: number) {
    try {
      const res = await api.get<SolicitacaoInternacao[]>(
        `/${ROUTE}/getByPaciente/${idPaciente}`
      );
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async count(query: FindManyReq<SolicitacaoInternacao>) {
    try {
      const res = await api.get<number>(`/${ROUTE}/count`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async insert(solicitacaoInternacao: SolicitacaoInternacao) {
    try {
      const res = await api.post<ResponseGenericType<SolicitacaoInternacao[]>>(
        `/${ROUTE}`,
        solicitacaoInternacao
      );

      const data = res.data.content;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new solicitacaoInternacaoApi();
