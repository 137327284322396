import { useEffect, useState } from "react";
import { Paciente } from "../../../../model/entities/Paciente";
import pacienteApi from "../../../../api/pacienteApi";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import TextFieldGeneric from "../../../Generics/TextFieldGeneric";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import TableGeneric from "../../../Generics/TableGeneric";
import InfoTabela from "../../../Generics/InfoTabela";
const columns: Paciente[] | any[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "nome",
    headerName: "Nome cidadão",
    width: 230,
    editable: false,
  },
];

type Props = {};

const TabelaPacientes: React.FC<Props> = (props) => {
  const [pacienteSelecionado, setPacienteSelecionado] =
    useState<Paciente | null>(null);
  const [pacientes, setPacientes] = useState<Paciente[]>([]);
  const [totalLinhas, setTotalLinhas] = useState(0);
  const [nomePaciente, setNomePaciente] = useState("");

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    buscarPacientes();
  }, []);

  async function buscarPacientes() {
    try {
      setLoading(true);
      const tot = await pacienteApi.count({});
      setTotalLinhas(tot);

      const res = await pacienteApi.getMany();
      setPacientes(res);

      setLoading(false);
    } catch (erro: any) {
      toast.error("Ops); Aconteceu um problema ao buscar os pacientes !!");
      console.log("erro ao buscar pacientes", erro);
      setLoading(false);
    }
  }

  const navegarParaPaciente = () => {
    if (pacienteSelecionado) {
      navigate(`/pacientes/${pacienteSelecionado.id}`, {
        state: { pacienteSelecionado },
      });
    }
  };

  return (
    <Grid
      item
      xs={12}
      style={{
        margin: "4% 4% 2% 4%",
        padding: "2px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Grid container xs={12} spacing={2} sm={12}>
        <InfoTabela mensagem="Para mais detalhes clique no paciente" />
        <Grid item xs={12} style={{ marginTop: "15px" }}>
          <TableGeneric
            colunas={columns}
            linhas={pacientes}
            loading={loading}
            totalLinhas={totalLinhas}
            pageSizeOptions={[10]}
            onPageChange={() => {}}
            onRowClick={(params) => {
              setPacienteSelecionado(params.row as Paciente);
              navegarParaPaciente();
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TabelaPacientes;
