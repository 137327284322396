import { useState } from "react";
import { Button, Grid, IconButton } from "@mui/material";

import { useNavigate } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import PerfilField from "../../../../Generics/Fields/PerfilField";
import { Perfil } from "../../../../../model/entities/Perfil";
import FormularioConfiguracaoPerfil from "./FormularioConfiguracaoPerfil";
import Container from "../../../../Generics/Container";

type Props = {};

const ConfiguracoesPerfilPage: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [perfilSelecionado, setPerfilSelecionado] = useState<Perfil | null>(
    null
  );

  return (
    <Container page>
      <Grid container xs={12} spacing={6}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {!perfilSelecionado && (
            <>
              <h3>Selecione um perfil para modificar</h3>

              <Button
                startIcon={<AddIcon />}
                onClick={() => {
                  setPerfilSelecionado(new Perfil());
                }}
                variant="contained"
              >
                Cadastrar novo perfil
              </Button>
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          <PerfilField
            perfil={perfilSelecionado}
            setPerfil={(p) => setPerfilSelecionado(p)}
          />
        </Grid>

        <Grid item xs={12}>
          {perfilSelecionado && (
            <FormularioConfiguracaoPerfil
              perfil={perfilSelecionado}
              setPerfil={(p) => setPerfilSelecionado({ ...p })}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ConfiguracoesPerfilPage;
