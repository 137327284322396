import { Usuario } from "../model/entities/Usuario";
import {
  DadosAtualizarUsuario,
  DadosCadastrarUsuario,
} from "../model/types/DadosCadastrarUsuario";
import { ResponseGenericType } from "../model/types/ResponseType";
import { FindManyReq } from "../model/types/TypeormTypes";
import api from "./api";

const PREFIX = "";
const ROUTE = "usuario";

class usuarioApi {
  async getMany() {
    try {
      const res = await api.get<ResponseGenericType<Usuario[]>>(`/${ROUTE}`);
      const data = res.data.content;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async count(query: FindManyReq<Usuario>) {
    try {
      const res = await api.get<number>(`/${ROUTE}/count`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async insert(dados: DadosCadastrarUsuario) {
    try {
      console.log("dados", dados);
      const res = await api.post<any>(`/${ROUTE}`, dados);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async update(usuario: DadosAtualizarUsuario) {
    try {
      const res = await api.put<number>(`/${ROUTE}`, usuario);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new usuarioApi();
