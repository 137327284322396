import { useState } from "react";
import { Button, Grid, IconButton } from "@mui/material";

import { useNavigate } from "react-router";
import UsuariosField from "../../../../Generics/Fields/UsuariosField";
import { Usuario } from "../../../../../model/entities/Usuario";
import FormularioConfiguracaoUsuario from "./FormularioConfiguracaoUsuario";
import AddIcon from "@mui/icons-material/Add";
import Container from "../../../../Generics/Container";

type Props = {};

const ConfiguracoesUsuariosPage: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [usuarioSelecionado, setUsuarioSelecionado] = useState<Usuario | null>(
    null
  );

  return (
    <Container page>
      <Grid container xs={12} spacing={6}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {!usuarioSelecionado && (
            <>
              <h3>Selecione um usuário para modificar</h3>

              <Button
                startIcon={<AddIcon />}
                onClick={() => {
                  setUsuarioSelecionado(new Usuario());
                }}
                variant="contained"
              >
                Cadastrar novo usuário
              </Button>
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          <UsuariosField setUsuario={(u) => setUsuarioSelecionado(u)} />
        </Grid>

        <Grid item xs={12}>
          {usuarioSelecionado && (
            <FormularioConfiguracaoUsuario
              usuario={usuarioSelecionado}
              setUsuario={(u) => setUsuarioSelecionado({ ...u })}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ConfiguracoesUsuariosPage;
