import axios from "axios";
import store from "../redux/store";
import { logoutAction } from "../redux/sessao/sessaoActions";
import { toast } from "react-toastify";
import { analytics } from "../firebase/firebaseConfig";

const api = axios.create({ timeout: 150000 });
const apiServer = axios.create({ timeout: 150000 });

api.interceptors.request.use(async (config) => {
  // // config.baseURL = "http://44.221.11.182:4500";
  // config.baseURL = "http://localhost:4500";
  const state = store.getState();
  const cfgGeral = state.cfgGeral;
  config.baseURL = cfgGeral.urlApi;
  const token = state.sessao.tokenJWT;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    console.log("vou mandar um evento pro firebase");
    analytics.logEvent("exception-api", {
      errorDescription: error,
    });
    // Request nem chegou no servidor
    if (!error.response) {
      console.log("ERRO!!! Não foi possível conectar com servidor.");

      return Promise.reject(
        new Error("Não foi possível estabelecer a conexão com o servidor.")
      );
    }
    console.log("error api", error);
    if (
      error.response &&
      error.response.data === "Token JWT invalido ou expirado!"
    ) {
      toast.error("Ops); O seu login expirou !!");
      console.log("unauthorized, logging out ...");
      const action = logoutAction();
      store.dispatch(action);
      return Promise.reject(
        new Error("Seu login expirou. Por favor, faça login novamente.")
      );
    }

    if (
      error &&
      error.response.data === "Usuário inexistente ou senha inválida"
    ) {
      console.log("uaii");
      return Promise.reject(new Error(error.response.data));
    }

    if (error) {
      return Promise.reject(new Error(error.response.data.message));
    }

    return Promise.reject(new Error(error.response));
  }
);

export default api;
