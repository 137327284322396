import { Card, Collapse, Grid, IconButton } from "@mui/material";
import { makeStyles } from "@material-ui/core";

import dayjs from "dayjs";
import Container from "../../../Generics/Container";

import { Paciente } from "../../../../model/entities/Paciente";
import TextFieldGeneric from "../../../Generics/TextFieldGeneric";
import { useEffect, useState } from "react";

import GenericDatePicker from "../../../Generics/DatePickerGeneric";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { useNavigate } from "react-router-dom";
import { Encaminhamento } from "../../../../model/entities/Encaminhamento";
import { SolicitacaoInternacao } from "../../../../model/entities/SolicitacaoInternacao";
import { ACIDENTES_TRABALHO } from "../../../../constants";
import RadioButonGroupGeneric from "../../../Generics/RadioButonGroupGeneric";

type Props = {
  paciente: Paciente;
  encaminhamentoSelecionado: Encaminhamento;
  solicitacaoInternacao: SolicitacaoInternacao;
  setSolicitacaoInternacao: (sl: SolicitacaoInternacao) => void;
};

const FomularioSolicitacaoInternacao: React.FC<Props> = (props) => {
  const {
    paciente,
    encaminhamentoSelecionado,
    solicitacaoInternacao,
    setSolicitacaoInternacao
  } = props;

  const [expandirJustificativa, setExpandirJustificativa] = useState(true);
  const [expandirProcedimentoSolicitado, setExpandirProcedimentoSolicitado] =
    useState(false);

  const [expandirCausasExternas, setExpandirCausasExternas] = useState(false);
  const [expandirAutorizacao, setExpandirAutorizacao] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setSolicitacaoInternacao({
      ...solicitacaoInternacao,
      idMedico: 1,
      idPaciente: 1,
      idPontoAtendimento: 1
    });
  }, []);

  return (
    <Grid display={"flex"} spacing={2}>
      <Grid item xs={2} style={{}}>
        <Card
          style={{
            display: "flex",
            flexDirection: "column",
            boxShadow: "1px 1px 5px #353353",
            position: "sticky",
            overflowY: "auto"
          }}
        >
          <a
            href="#justificativa_internacao"
            onClick={() => {
              navigate(
                `/encaminhamentos/${encaminhamentoSelecionado.id}/#justificativa_internacao`,
                {
                  state: { encaminhamentoSelecionado }
                }
              );
              setExpandirProcedimentoSolicitado(true);
            }}
            style={{
              color: "inherit",
              textDecoration: "inherit",
              margin: "5px",
              padding: "1px"
            }}
          >
            Justificativa da Internação
          </a>

          <a
            href="#procedimentoSolicitado"
            onClick={() => {
              navigate(
                `/encaminhamentos/${encaminhamentoSelecionado.id}/#procedimentoSolicitado`,
                {
                  state: { encaminhamentoSelecionado }
                }
              );
              setExpandirProcedimentoSolicitado(true);
            }}
            style={{
              color: "inherit",
              textDecoration: "inherit",
              margin: "5px",
              padding: "1px"
            }}
          >
            Procedimento Solicitado
          </a>

          <a
            href="#acidenteViolencia"
            onClick={() => {
              navigate(
                `/encaminhamentos/${encaminhamentoSelecionado.id}/#acidenteViolencia`,
                {
                  state: { encaminhamentoSelecionado }
                }
              );
              setExpandirCausasExternas(true);
            }}
            style={{
              color: "inherit",
              textDecoration: "inherit",
              margin: "5px",
              padding: "1px"
            }}
          >
            Causas Externas (Acidentes ou Violência)
          </a>

          <a
            href="#autorizacao"
            onClick={() => {
              navigate(
                `/encaminhamentos/${encaminhamentoSelecionado.id}/#autorizacao`,
                {
                  state: { encaminhamentoSelecionado }
                }
              );
              setExpandirAutorizacao(true);
            }}
            style={{
              color: "inherit",
              textDecoration: "inherit",
              margin: "5px",
              padding: "1px"
            }}
          >
            Autorizacao
          </a>
        </Card>
      </Grid>

      <Grid container spacing={2} style={{ margin: "15px" }}>
        <Container
          style={{ marginBottom: "2rem", boxShadow: "1px 1px 5px #353353" }}
        >
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              style={{
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.87)"
              }}
            >
              <IconButton
                onClick={() => setExpandirJustificativa(!expandirJustificativa)}
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  fontSize: "22px"
                }}
              >
                {expandirJustificativa ? (
                  <KeyboardArrowUpOutlinedIcon />
                ) : (
                  <KeyboardArrowDownOutlinedIcon />
                )}

                <h3 style={{ fontSize: "22px" }}>Justificava da Internação</h3>
              </IconButton>
            </Grid>

            <Collapse in={expandirJustificativa}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextFieldGeneric
                    label="Principais sinais e sintomas clinicos"
                    value={solicitacaoInternacao.principaisSintomas}
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        principaisSintomas: value
                      });
                    }}
                    minRows={4}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextFieldGeneric
                    label="Principais resultados de provas diagnóstixcas (Resultado de exames realizados)"
                    value={solicitacaoInternacao.principaisResultado}
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        principaisResultado: value
                      });
                    }}
                    minRows={3}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextFieldGeneric
                    label="Diagnóstico Inicial"
                    value={solicitacaoInternacao.diagnosticoInicail}
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        diagnosticoInicail: value
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextFieldGeneric
                    label="CID 10 principal"
                    value={solicitacaoInternacao.cidPrincipal}
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        cidPrincipal: value
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextFieldGeneric
                    label="CID 10 secundario"
                    value={solicitacaoInternacao.cidSecundario}
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        cidSecundario: value
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextFieldGeneric
                    label="CID 10 causas associadas"
                    value={solicitacaoInternacao.cidCausasAssociadas}
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        cidCausasAssociadas: value
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Container>
        <div id="justificativa_internacao" />

        <Container
          style={{ marginBottom: "2rem", boxShadow: "1px 1px 5px #353353" }}
        >
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              style={{
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.87)"
              }}
            >
              <IconButton
                onClick={() =>
                  setExpandirProcedimentoSolicitado(
                    !expandirProcedimentoSolicitado
                  )
                }
              >
                {expandirProcedimentoSolicitado ? (
                  <KeyboardArrowUpOutlinedIcon />
                ) : (
                  <KeyboardArrowDownOutlinedIcon />
                )}
                <Grid item xs={12}>
                  <h3 style={{ fontSize: "22px" }}>Procedimento solicitado</h3>
                </Grid>
              </IconButton>
            </Grid>

            <Collapse in={expandirProcedimentoSolicitado}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <TextFieldGeneric
                    label="Descrição do procedimento solicitado"
                    value={solicitacaoInternacao.procedimentoSolicitado}
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        procedimentoSolicitado: value
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextFieldGeneric
                    label="Código do procedimento"
                    value={solicitacaoInternacao.codigoProcedimento}
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        codigoProcedimento: value
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextFieldGeneric
                    label="Clínica"
                    value={solicitacaoInternacao.clinica}
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        clinica: value
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextFieldGeneric
                    label="Caráter da internação"
                    value={solicitacaoInternacao.caraterInternacao}
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        caraterInternacao: value
                      });
                    }}
                  />
                </Grid>

                {/* <Grid item xs={2.5}>
                  <RadioButonGroupGeneric
                    labelGroup={"Documento"}
                    options={[
                      { label: "CPF", value: "CPF" },
                      { label: "CNS", value: "CNS" }
                    ]}
                  />
                </Grid> */}

                <Grid item xs={3.5}>
                  <TextFieldGeneric
                    label="Nº Documento do profissional solicitante assistente"
                    value={
                      solicitacaoInternacao.documentoProfissionalSolicitanteAssistente
                    }
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        documentoProfissionalSolicitanteAssistente: value
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={5}>
                  <TextFieldGeneric
                    label="Nome do profissional solicitante assistente"
                    value={
                      solicitacaoInternacao.nomeProfissionalSolicitanteAssistente
                    }
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        nomeProfissionalSolicitanteAssistente: value
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <GenericDatePicker
                    label="Data da solicitação"
                    value={dayjs(solicitacaoInternacao.dataSolicitacao)}
                    setValue={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        dataSolicitacao: value.toDate()
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextFieldGeneric
                    label="Assinatura e carimbo (Nº do registro do conselho)"
                    value={""}
                    minRows={4}
                    onChange={(value) => {}}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Container>
        <div id="procedimentoSolicitado" />

        <Container
          style={{ marginBottom: "2rem", boxShadow: "1px 1px 5px #353353" }}
        >
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              style={{
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.87)"
              }}
            >
              <IconButton
                onClick={() =>
                  setExpandirCausasExternas(!expandirCausasExternas)
                }
              >
                {expandirCausasExternas ? (
                  <KeyboardArrowUpOutlinedIcon />
                ) : (
                  <KeyboardArrowDownOutlinedIcon />
                )}
                <Grid item xs={12}>
                  <h3 style={{ fontSize: "20px" }}>
                    Preencher em caso de causa externas (Acidentes ou Violência)
                  </h3>
                </Grid>
              </IconButton>
            </Grid>

            <Collapse in={expandirCausasExternas}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <RadioButonGroupGeneric
                    labelGroup={"Situação no mercado de trabalho"}
                    value={solicitacaoInternacao.acidente}
                    options={ACIDENTES_TRABALHO}
                    row
                    setValue={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        acidente: value
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextFieldGeneric
                    label="CNPJ da seguradora"
                    value={solicitacaoInternacao.cnpjSeguradora}
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        cnpjSeguradora: value
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextFieldGeneric
                    label="N° Do bilhete"
                    value={solicitacaoInternacao.noBilhete}
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        noBilhete: value
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextFieldGeneric
                    label="Série"
                    value={solicitacaoInternacao.serie}
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        serie: value
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={3} />

                <Grid item xs={3}>
                  <TextFieldGeneric
                    label="CNPJ da EMPRESA"
                    value={solicitacaoInternacao.cnpjEmpresa}
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        cnpjEmpresa: value
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextFieldGeneric
                    label="CNAE da empresa"
                    value={solicitacaoInternacao.cnaeEmpresa}
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        cnaeEmpresa: value
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextFieldGeneric
                    label="CBOR"
                    value={solicitacaoInternacao.cbor}
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        cbor: value
                      });
                    }}
                  />
                </Grid>

                {/* <Grid item xs={12}>
                  <RadioButonGroupGeneric
                    labelGroup={"Vinculo com a previdência"}
                    options={[
                      {
                        label: "Empregado",
                        value: "Empregado"
                      },
                      {
                        label: "Empregador",
                        value: "Empregador"
                      },
                      {
                        label: "Autonômo",
                        value: "Autonômo"
                      },
                      {
                        label: "Empregador",
                        value: "Empregador"
                      },
                      {
                        label: "Desempregado",
                        value: "Desempregado"
                      },
                      {
                        label: "Aposentado",
                        value: "Aposentado"
                      },
                      {
                        label: "Não Assegurado",
                        value: "Não Assegurado"
                      }
                    ]}
                  />
                </Grid> */}
              </Grid>
            </Collapse>
          </Grid>
        </Container>
        <div id="acidenteViolencia" />

        <Container
          style={{ marginBottom: "2rem", boxShadow: "1px 1px 5px #353353" }}
        >
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              style={{
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.87)"
              }}
            >
              <IconButton
                onClick={() => setExpandirAutorizacao(!expandirAutorizacao)}
              >
                {expandirAutorizacao ? (
                  <KeyboardArrowUpOutlinedIcon />
                ) : (
                  <KeyboardArrowDownOutlinedIcon />
                )}
                <Grid item xs={12}>
                  <h3 style={{ fontSize: "20px" }}>AUTORIZAÇÃO</h3>
                </Grid>
              </IconButton>
            </Grid>

            <Collapse in={expandirAutorizacao}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <GenericDatePicker
                    label="Data da autorização"
                    value={dayjs()}
                    setValue={() => {}}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldGeneric
                    label="Nome do profissional autorizador"
                    value={solicitacaoInternacao.nomeProfissionalAutorizador}
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        nomeProfissionalAutorizador: value
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextFieldGeneric
                    label="Cód órgão emissor"
                    value={solicitacaoInternacao.codOrgaoEmissor}
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        codOrgaoEmissor: value
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextFieldGeneric
                    label="Documento"
                    value={solicitacaoInternacao.documento}
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        documento: value
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextFieldGeneric
                    label="N° Documento (CNS / CPF) do profissional autorizador"
                    value={
                      solicitacaoInternacao.documentoProfissionalAutorizador
                    }
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        documentoProfissionalAutorizador: value
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextFieldGeneric
                    label="N° da AUT. de internação hospitalar"
                    value={solicitacaoInternacao.noAutInternacao}
                    onChange={(value) => {
                      setSolicitacaoInternacao({
                        ...solicitacaoInternacao,
                        noAutInternacao: value
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextFieldGeneric
                    label="Assinatura e carimbo (N° do registro do conselho)"
                    value={""}
                    minRows={5}
                    onChange={(value) => {}}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Container>
        <div id="autorizacao" />
      </Grid>
    </Grid>
  );
};

export default FomularioSolicitacaoInternacao;
