import { Pessoa } from "./Pessoa";

export class Paciente extends Pessoa {
  id!: number;
  nomeResponsavell!: string;
  numeroSus!: string;
  observacao!: string;
  classificacaoRisco!: string;
  municipioNascimento!: string;
}
