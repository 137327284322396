import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { Paciente } from "../../../../../../model/entities/Paciente";

import InfoTabela from "../../../../../Generics/InfoTabela";
import { SolicitacaoInternacao } from "../../../../../../model/entities/SolicitacaoInternacao";
import solicitacaoInternacaoApi from "../../../../../../api/solicitacaoInternacaoApi";

const columns: SolicitacaoInternacao[] | any[] = [
  {
    field: "classificacaoRisco",
    headerName: "Classificação de risco",
    width: 200,
    editable: false
  },
  {
    field: "unidadeSolicitante",
    headerName: "P.A. Solicitante",
    width: 350,
    editable: false
  },
  {
    field: "profissionalSolicitante",
    headerName: "Prof. Solicitante",
    width: 350,
    editable: false
  },

  {
    field: "motivoEncaminhamento",
    headerName: "Motivo do encaminhamento",
    width: 250,
    editable: false
  },
  {
    field: "hipotese",
    headerName: "Hipótese / Diagnóstico (CID10)",
    width: 250,
    editable: false
  },

  {
    field: "dataEncaminhamento",
    headerName: "Data da solicitação",
    width: 150,
    editable: false,
    renderCell: (params: SolicitacaoInternacao) => {
      return dayjs(params.dataSolicitacao).format("DD/MM/YYYY");
    }
  }
];

type Props = {
  paciente: Paciente;
  onRowClick: (receita: SolicitacaoInternacao) => void;
};

const TabelaSolicitacoesInternacoes: React.FC<Props> = (props) => {
  const { onRowClick, paciente } = props;

  const [solicitacoes, setSolicitacoes] = useState<SolicitacaoInternacao[]>([]);

  const [loading, setLoading] = useState(false);
  const [pagina, setPagina] = useState(0);

  // useEffect(() => {
  //   buscarDadosPaciente();
  // }, []);

  // const buscarDadosPaciente = async () => {
  //   try {
  //     const resSolicitacoes = await solicitacaoInternacaoApi.getByPaciente(
  //       paciente.id
  //     );

  //     setSolicitacoes(resSolicitacoes);
  //   } catch (erro: any) {
  //     toast.error(`Erro ao buscar informações do paciente`);
  //   }
  // };

  return (
    <Grid container xs={12} sm={12} spacing={2}>
      <InfoTabela mensagem="Para mais detalhes clique no encaminhamento" />
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        <DataGrid
          columns={columns}
          rows={solicitacoes}
          loading={loading}
          rowCount={solicitacoes.length}
          pagination={true}
          onRowClick={(params) => {
            onRowClick(params.row as SolicitacaoInternacao);
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } }
          }}
          pageSizeOptions={[5, 10, 25]}
          style={{
            height: 400,
            width: "75%",
            textAlign: "center",
            border: "1px solid black",
            boxShadow: "1px 1px 10px #353353"
          }}
        />
      </Grid>
    </Grid>
  );
};

export default TabelaSolicitacoesInternacoes;
