import { useState } from "react";
import { Button, Grid } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { PontoAtendimento } from "../../../../../model/entities/PontoAtendimento";
import PontoAtendimentoField from "../../../../Generics/Fields/PontoAtendimentoField";
import FormularioConfiguracaoPontoAtendimento from "./FormularioConfiguracaoPontoAtendimento";
import Container from "../../../../Generics/Container";

const ConfiguracoesPontoAtendimentoPage: React.FC = () => {
  const [pontoAtendimentoSelecionado, setPontoAtendimentoSelecionado] =
    useState<PontoAtendimento | null>(null);

  return (
    <Container page>
      <Grid container xs={12} spacing={6}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {!pontoAtendimentoSelecionado && (
            <>
              <h3>Selecione um ponto de atendimento para modificar</h3>

              <Button
                startIcon={<AddIcon />}
                onClick={() => {
                  setPontoAtendimentoSelecionado(new PontoAtendimento());
                }}
                variant="contained"
              >
                Cadastrar novo P.A
              </Button>
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          <PontoAtendimentoField
            pontoAtendimento={pontoAtendimentoSelecionado}
            setPontoAtendimento={(pa) => setPontoAtendimentoSelecionado(pa)}
          />
        </Grid>

        <Grid item xs={12}>
          {pontoAtendimentoSelecionado && (
            <FormularioConfiguracaoPontoAtendimento
              pontoAtendimentoSelecionado={pontoAtendimentoSelecionado}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ConfiguracoesPontoAtendimentoPage;
