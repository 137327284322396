import { CheckFat } from "@phosphor-icons/react";
import { Encaminhamento } from "../model/entities/Encaminhamento";
import { DadosAtualizarEncaminhamento } from "../model/types/EncaminhamentoTypes";
import api from "./api";

const PREFIX = "";
const ROUTE = "encaminhamento";

class encaminhamentoApi {
  async insert(encaminhamento: Encaminhamento) {
    try {
      const res = await api.post<Encaminhamento>(`/${ROUTE}`, encaminhamento);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async upload(file: File) {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await api.post<number>(`/${ROUTE}/uploadFile`, formData);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getAll() {
    try {
      const res = await api.get<Encaminhamento[]>(`/${ROUTE}`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getByPaciente(idPaciente: number) {
    try {
      const res = await api.get<Encaminhamento[]>(
        `/${ROUTE}/getByPaciente/${idPaciente}`
      );
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getEncaminhamentoByCpf(cpfPaciente: string) {
    try {
      const res = await api.get<Encaminhamento[]>(
        `/${ROUTE}/encaminhamentos/${cpfPaciente}`
      );
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async update(
    idEncaminhamento: number,
    dadosAtualizarEncaminhamento: DadosAtualizarEncaminhamento
  ) {
    try {
      const res = await api.put<Encaminhamento[]>(
        `/${ROUTE}/${idEncaminhamento}`,
        dadosAtualizarEncaminhamento
      );
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export const statusEncaminhamento = (encaminhamento: Encaminhamento) => {
  return (
    <span
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      {encaminhamento?.contraReferencia?.diagnostico && (
        <div>
          <span>
            <CheckFat size={32} color="#08f7a7" weight="fill" />
          </span>
        </div>
      )}
    </span>
  );
};

export default new encaminhamentoApi();
