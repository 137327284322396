import * as React from "react";
import TextField from "@mui/material/TextField";
import { Paciente } from "../../../model/entities/Paciente";
import { useEffect, useState } from "react";
import { Autocomplete } from "@mui/material";
import pacienteApi from "../../../api/pacienteApi";

type Props = {
  sx: number;
  setPaciente: (p: Paciente) => void;
  error?: boolean | undefined;
  helperText?: React.ReactNode;
};

const PacienteField: React.FC<Props> = (props) => {
  const { sx, setPaciente, error, helperText, ...rest } = props;

  const [pacientes, setPacientes] = useState<Paciente[]>([]);

  useEffect(() => {
    buscarPacientes();
  }, []);

  const buscarPacientes = async () => {
    try {
      const res = await pacienteApi.getMany();
      setPacientes(res);
    } catch (erro: any) {
      console.log("Erro ao buscar PAS.");
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Autocomplete
        id="filter-pacientes"
        options={pacientes}
        getOptionLabel={(option) => option.nome}
        onChange={(event, value) => {
          if (value) setPaciente(value);
        }}
        sx={{ width: sx }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...rest}
            error={error}
            helperText={helperText}
            variant={"outlined"}
            label={"Pacientes"}
            placeholder={"Paciente"}
            multiline={true}
          />
        )}
      />
    </div>
  );
};

export default PacienteField;
