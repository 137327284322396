export const LISTA_SEXOS = [
  { decricao: "Masculino" },
  { decricao: "Feminino" },
  { decricao: "Outro" },
];

export const LISTA_TIPOS_PONTO_ATENDIMENTO = [
  { codigo: 1, descricao: "Atenção primária", value: "ATENCAO_PRIMARIA" },
  {
    codigo: 2,
    descricao: "Atenção especializada",
    value: "ATENCAO_ESPECIALIZADA",
  },
  { codigo: 3, descricao: "Atenção Hospitalar", value: "ATENCAO_HOSPITALAR" },
];

export const Ortese_Protese_Adaptacao = [
  { label: "Ortese", value: "1" },
  { label: "Protese", value: "2" },
  { label: "Adaptacao", value: "3" },
];

export const ATIVIDADE_DEIXOU_REALIZAR = [
  {
    codigo: 1,
    descricao: "AVD (Ir ao banheiro, trocar de roupa, higiene pessoal, outras)",
  },
  {
    codigo: 2,
    descricao:
      "AIVD (Dirigir, pegar transporte público, fazer compras realizar atividades domésticas, outros)",
  },
  { codigo: 3, descricao: "Outros" },
];

export const INICIO_SINTOMAS = [
  { label: "Há menos de 1 mês", value: "1" },
  { label: "Entre 1 e 3 meses", value: "2" },
  { label: "Entre 3 e 6 meses", value: "3" },
  { label: "Entre 6 meses e 1 ano", value: "4" },
  { label: "Há mais de 1 ano", value: "5" },
];

export const SITUACAO_MERCADO_TRABALHO = [
  { label: "Trabalhando", value: "1" },
  { label: "Afastado", value: "2" },
  { label: "Desempregado", value: "3" },
  { label: "Aposentado", value: "4" },
];

export const ACIDENTES_TRABALHO = [
  { label: "Acidente de Tránsito", value: "1" },
  { label: "Acidente de trabalho típico", value: "2" },
  { label: "Acidente trabalho trajeto", value: "3" },
];

export const ACESSO_VINCULADO_TODOS = [
  { codigo: 1, descricao: "Todos" },
  { codigo: 2, descricao: "Vinculado" },
];
