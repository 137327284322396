import { PontoAtendimento } from "../model/entities/PontoAtendimento";
import { ResponseGenericType } from "../model/types/ResponseType";
import { FindManyReq } from "../model/types/TypeormTypes";
import {
  DadosAtualizarPontoAtendimento,
  DadosCadastrarPontoAtendimento,
} from "../model/types/DadosCadastrarPontoAtendimento";
import api from "./api";

const PREFIX = "";
const ROUTE = "pontoAtendimento";

class pontoAtendimentoApi {
  async count() {
    try {
      const res = await api.get<number>(`/${ROUTE}/count`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getMany(size: number, page: number) {
    try {
      const res = await api.get<ResponseGenericType<PontoAtendimento[]>>(
        `/${ROUTE}?size=${size}&page=${page}`
      );
      const data = res.data.content;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async insert(dados: DadosCadastrarPontoAtendimento) {
    try {
      const res = await api.post<any>(`/${ROUTE}`, dados);
      const data = res.data.content;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async update(dados: DadosAtualizarPontoAtendimento) {
    try {
      const res = await api.put<any>(`/${ROUTE}`, dados);
      const data = res.data.content;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new pontoAtendimentoApi();
