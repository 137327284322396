import { Paciente } from "../model/entities/Paciente";
import { ResponseGenericType } from "../model/types/ResponseType";
import { FindManyReq } from "../model/types/TypeormTypes";
import api from "./api";

const PREFIX = "";
const ROUTE = "paciente";

class pacienteApi {
  async getMany() {
    try {
      const res = await api.get<ResponseGenericType<Paciente[]>>(`/${ROUTE}`);
      const data = res.data.content;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async count(query: FindManyReq<Paciente>) {
    try {
      const res = await api.get<number>(`/${ROUTE}/count`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new pacienteApi();
