import {
  Button,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import logoFormiga from "../../../../images/brasao_formiga.png";
import logoSus from "../../../../images/LOGO-SUS.png";
import { useEffect, useState } from "react";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import { useSessao } from "../../../../redux/sessao/sessaoHooks";
import { makeStyles } from "@material-ui/core/styles";
import { ModalCfgApp } from "../../../Modais/ModalCfgApp";

const LoginPage: React.FC = () => {
  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");
  const [verSenha, setVerSenha] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sessao, login, revalidate] = useSessao();
  const [openModalCfg, setOpenModalCfg] = useState(false);
  const classes = useStyles();

  const onLogin = async () => {
    try {
      setLoading(true);
      await login(usuario, senha);
      setLoading(false);
    } catch (err: any) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      toast.error(`Erro ao efetuar login ${err}`);
      console.log("Erro no login", err);
    }
  };

  useEffect(() => {
    const callback = (event: any) => {
      console.log(event.keys);
      if (event.key === "*") {
        event.preventDefault();
        setOpenModalCfg(true);
      }
    };
    document.addEventListener("keydown", callback);

    return () => {
      document.removeEventListener("keydown", callback);
    };
  }, []);

  return (
    <Grid className={classes.body}>
      <Grid
        item
        xs={12}
        style={{
          background: "linear-gradient(to right, #3692dd, #e0e0e0)",
          height: "100%",
          color: "#ffffff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={logoFormiga}
          alt="Brasão Município Formiga-MG"
          style={{ maxWidth: "20%", marginBottom: "30px" }}
        />

        <TextField
          style={{
            width: 350,
            borderColor: "#fff",
            minWidth: "250px",
            marginBottom: "30px",
          }}
          // autoFocus
          label="Usuário"
          value={usuario}
          onChange={(event) => setUsuario(event.target.value)}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              onLogin();
            }
          }}
          disabled={loading}
        />

        <TextField
          style={{
            width: 350,

            minWidth: "250px",
            marginBottom: "30px",
          }}
          label="Senha"
          value={senha}
          onChange={(event) => setSenha(event.target.value)}
          // className={classes.input}
          type={verSenha ? "text" : "password"}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              onLogin();
            }
          }}
          disabled={loading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setVerSenha(!verSenha)}
                  // onMouseDown={handleMouseDownPassword}
                >
                  {verSenha ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          variant="contained"
          onClick={() => {
            onLogin();
          }}
        >
          Entrar
        </Button>
      </Grid>

      <Hidden mdDown>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            // background: "linear-gradient(to right, #e0e0e0, #3692dd)"
          }}
        >
          <h2
            style={{
              fontSize: "36px" /* Tamanho da fonte */,
              // background: "linear-gradient(to right, #e0e0e0, #3692dd)"
              color: "#2a2ae4" /* Cor principal do texto */,
              textShadow: "2px 2px 0px #0a0a0a , 1px 1px 1px #000",
            }}
          >
            SISUS - Sistema Integrado do SUS
          </h2>

          <img
            src={logoSus}
            alt=""
            style={{ maxWidth: "20%", marginBottom: "30px" }}
          />
        </Grid>
      </Hidden>

      {openModalCfg && (
        <ModalCfgApp
          open={openModalCfg}
          onClose={() => setOpenModalCfg(false)}
        />
      )}
    </Grid>
  );
};

export default LoginPage;
const useStyles = makeStyles({
  body: {
    display: "grid",
    gridTemplateColumns: "0.5fr 1fr",
    height: "100vh",
    "@media (max-width: 768px)": {
      gridTemplateColumns: "1fr", // Uma coluna quando a largura da tela for menor ou igual a 768px
    },
  },
});
