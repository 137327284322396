import * as React from "react";
import TextField from "@mui/material/TextField";
import { PontoAtendimento } from "../../../model/entities/PontoAtendimento";
import { useEffect, useState } from "react";
import pontoAtendimentoApi from "../../../api/pontoAtendimentoApi";
import { Autocomplete } from "@mui/material";
import { useSessao } from "../../../redux/sessao/sessaoHooks";
import theme from "../../../theme";

type Props = {
  label?: string;
  setPontoAtendimento: (p: PontoAtendimento | null) => void;
  pontoAtendimento: PontoAtendimento | null;
  disable?: boolean;
};
const PontoAtendimentoField: React.FC<Props> = (props) => {
  const { setPontoAtendimento, pontoAtendimento, label, disable } = props;

  const [pontosAtendimento, setPontosAtendimento] = useState<
    PontoAtendimento[]
  >([]);

  const [sessao] = useSessao();

  useEffect(() => {
    buscarPontosAtendimento();
  }, []);

  const buscarPontosAtendimento = async () => {
    try {
      const qntLinhas = await pontoAtendimentoApi.count();
      const res = await pontoAtendimentoApi.getMany(qntLinhas, 0);
      setPontosAtendimento(res);

      if (sessao.usuario?.pontoAtendimento) {
        setPontoAtendimento({ ...sessao.usuario?.pontoAtendimento });
      }

      if (pontoAtendimento) {
        setPontoAtendimento({ ...pontoAtendimento });
      }
    } catch (erro: any) {
      console.log("Erro ao buscar PAS.");
    }
  };
  const xsValue = theme.breakpoints.down("sm") ? 12 : 6;

  return (
    <div style={{ textAlign: "center" }}>
      <Autocomplete
        id="filter-pontoAtendimento"
        disabled={disable}
        options={pontosAtendimento}
        value={pontoAtendimento}
        getOptionLabel={(option) => option.nome ?? "Novo P.A"}
        onChange={(event, value) => {
          setPontoAtendimento(value);
        }}
        sx={{ xsValue }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={"outlined"}
            label={label ? label : "Ponto de Atendimento"}
            placeholder={"Ponto de Atendimento"}
            multiline={true}
          />
        )}
      />
    </div>
  );
};

export default PontoAtendimentoField;
