import { Endereco } from "./Endereco";

export class PontoAtendimento {
  id!: number;

  nome!: string;

  endereco!: Endereco;

  tipo!: string;

  nomeResponsavel!: string;

  telefone!: string;

  created_at!: Date;

  updated_at!: Date;

  cnes!: string;
}
