import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Container } from "@material-ui/core";
import theme, { colors } from "../../../../theme";
import Copyright from "../../../layout/Copyrigth";

const BloqueioEmpresaPage: React.FC = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="md" className={classes.body}>
      <Typography variant="h1" component="h2" className={classes.title}>
        Acesso Negado
      </Typography>
      <div className={classes.content}>
        <Typography variant="h5" component="p">
          Lamentamos informar que o acesso ao serviço foi temporariamente
          suspenso devido à ausência de licença de uso válida. Para resolver
          essa questão e retomar o acesso, solicitamos que entre em contato
          conosco pelo número (37) 9-9808-9731.
        </Typography>
      </div>
      <footer className={classes.footer}>
        <Copyright textColor={colors.primary} />
      </footer>
    </Container>
  );
};
const useStyles = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    textAlign: "center",
  },
  title: {
    marginBottom: theme.spacing(3),
    color: colors.primary,
  },
  content: {
    marginBottom: theme.spacing(4),
    color: colors.drawer,
  },
  footer: {
    // background: colors.grey,s
    color: colors.primary,
    textAlign: "center",
    width: "100%",
  },
});
export default BloqueioEmpresaPage;
