import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import sessaoReducer from "./sessao/sessaoReducer";
import appStateSlice from "./features/appStateSlice";
import cfgGeralReducer from "./cfgGeral/cfgGeralReducer";

export const rootReducer = combineReducers({
  appState: appStateSlice,
  sessao: sessaoReducer,
  cfgGeral: cfgGeralReducer,
});

const persistConfig = {
  key: "@redux",
  storage,
  blacklist: ["snack", "loadingGeral", "dialog"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type RootAppState = ReturnType<typeof persistedReducer>;

const store = configureStore({
  reducer: persistedReducer,
  // middleware: getDefaultMiddleware({
  //   serializableCheck: {
  //     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  //   },
  // }),
});
export const persistor = persistStore(store);

export default store;
