import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDQ6fCVeYWdENUiyC7I4H_YjScSbIQ1YQM",
  authDomain: "sisus-7a08e.firebaseapp.com",
  projectId: "sisus-7a08e",
  storageBucket: "sisus-7a08e.appspot.com",
  messagingSenderId: "956299624793",
  appId: "1:956299624793:web:d78af7da32b71da9094c48",
  measurementId: "G-08E9KZLM6S",
};

// Inicialize o Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // Se já estiver inicializado, use a instância existente
}

// Inicialize o Firebase Analytics separadamente
const analytics = firebase.analytics();

export { analytics };
