import { Grid, TextField } from "@material-ui/core";
import ModalGeneric from "../Generics/ModalGeneric";
import { useCfgGeral } from "../../redux/cfgGeral/cfgGeralHooks";
import TextFieldGeneric from "../Generics/TextFieldGeneric";
import { Button } from "@mui/material";
import { useState } from "react";

type Props = {
  open: boolean;
  onClose: () => void;
};

export function ModalCfgApp(props: Props) {
  const { open, onClose } = props;
  const [cfgGeral, setCfgGeral] = useCfgGeral();
  const [url, setUrl] = useState("");

  return (
    <ModalGeneric open={open} onClose={() => onClose()}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextFieldGeneric
            label="URL"
            onChange={(value) => setUrl(value)}
            value={url}
          />
        </Grid>

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            onClick={() => {
              setCfgGeral({ ...cfgGeral, urlApi: url });
              onClose();
            }}
            children={"Salvar"}
          />
        </Grid>
      </Grid>
    </ModalGeneric>
  );
}
