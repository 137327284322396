import Modal from "@mui/material/Modal";

import { useSpring, animated } from "@react-spring/web";

import React, { Children, useState } from "react";
import Grid from "@material-ui/core/Grid/Grid";
import { Container, IconButton, Paper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
  props,
  ref
) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

// const style = {
//   position: "absolute" as "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

type ModalProps = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

export default function ModalGeneric(props: ModalProps) {
  const { open, onClose, children } = props;

  return (
    <div>
      <Modal
        open={open}
        onClose={() => onClose()}
        closeAfterTransition
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={open}>
          <Paper
            elevation={5}
            style={{
              minWidth: "min(100vw, 500px)",
              maxWidth: "min(100vw, 800px)",
              marginTop: 10,
              marginBottom: 10,
              position: "relative",
              paddingTop: 53,
            }}
          >
            <IconButton
              onClick={() => onClose()}
              style={{
                height: 53,
                width: 53,
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <CloseIcon />
            </IconButton>
            <div
              style={{
                // maxWidth: '900px',
                maxHeight: "calc(100vh - 70px)",
                overflow: "auto",
                paddingTop: 10,
              }}
            >
              <div
                style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}
              >
                {children}
              </div>
            </div>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
}
