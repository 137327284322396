import { Perfil } from "../model/entities/Perfil";
import {
  DadosAtualizarPerfil,
  DadosCadastrarPerfil,
} from "../model/types/PerfilTypes";
import { ResponseGenericType } from "../model/types/ResponseType";
import { FindManyReq } from "../model/types/TypeormTypes";
import api from "./api";

const PREFIX = "";
const ROUTE = "perfil";

class perfilApi {
  async getMany() {
    try {
      const res = await api.get<ResponseGenericType<Perfil[]>>(`/${ROUTE}`);
      const data = res.data.content;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async count(query: FindManyReq<Perfil>) {
    try {
      const res = await api.get<number>(`/${ROUTE}/count`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
  async insert(dados: DadosCadastrarPerfil) {
    try {
      const res = await api.post<any>(`/${ROUTE}`, dados);
      const data = res.data.content;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async update(dados: DadosAtualizarPerfil) {
    try {
      const res = await api.put<any>(`/${ROUTE}`, dados);
      const data = res.data.content;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new perfilApi();
