import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import { Receita } from "../../../../../../model/entities/Receita";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import receitaApi from "../../../../../../api/receitaApi";
import { Paciente } from "../../../../../../model/entities/Paciente";
import InfoTabela from "../../../../../Generics/InfoTabela";
import TableGeneric from "../../../../../Generics/TableGeneric";

const columns: Receita[] | any[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "prescricao",
    headerName: "Prescrição",
    width: 400,
    editable: false
  },
  {
    field: "medicoResponsavel",
    headerName: "Prescrito por",
    width: 300,
    editable: false
  },
  {
    field: "nomePontoAtendimento",
    headerName: "Unidade de atendimento",
    width: 500,
    editable: false
  },

  {
    field: "data",
    headerName: "Data da Receita",
    width: 230,
    editable: false,
    renderCell: (params: Receita) => {
      return dayjs(params.data).format("DD/MM/YYYY");
    }
  }
];

type Props = {
  paciente: Paciente;
  onRowClick: (receita: Receita) => void;
};

const TabelaReceitas: React.FC<Props> = (props) => {
  const { onRowClick, paciente } = props;

  const [receitas, setReceitas] = useState<Receita[]>([]);

  const [loading, setLoading] = useState(false);
  const [pagina, setPagina] = useState(0);

  useEffect(() => {
    buscarDadosPaciente();
  }, []);

  const buscarDadosPaciente = async () => {
    try {
      const resReceitas = await receitaApi.getByPaciente(paciente.id);

      setReceitas(resReceitas);
    } catch (erro: any) {
      toast.error(`Erro ao buscar informações do paciente`);
    }
  };

  return (
    <Grid container xs={12} sm={12} spacing={2}>
      <InfoTabela mensagem=" Para mais detalhes clique na receita" />

      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        <TableGeneric
          colunas={columns}
          linhas={receitas}
          loading={loading}
          pageSizeOptions={[5, 10, 25]}
          onPageChange={(pageNumber) => {
            setPagina(pageNumber);
          }}
          onRowClick={(params) => {
            onRowClick(params.row as Receita);
          }}
          disableSelectionOnClick
          totalLinhas={receitas.length}
        />
      </Grid>
    </Grid>
  );
};

export default TabelaReceitas;
