import { useState } from "react";
import { Paciente } from "../../../../../model/entities/Paciente";
import { Box, Button, Grid, Typography } from "@mui/material";
import TabPanelDadosPaciente from "../TabPanelDadosPaciente";
import TabPanelEncaminhamentos from "../TabPanelEncaminhamentos";
import { useLocation } from "react-router";
import { ArrowLeft, ArrowRight, FloppyDisk } from "@phosphor-icons/react";
import ReceitasProntuario from "./Receita/ReceitasProntuario";
import AtestadosProntuario from "./Atestado/AtestadosProntuario";

import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import EncaminhamentoEspecialistas from "./EncaminhamentoEspecialistas/EncaminhamentosEspecialistas";
import { SolicitacaoInternacao } from "../../../../../model/entities/SolicitacaoInternacao";
import SolicitacoesInternacaoPage from "./SolicitacoesInternacao/SolicitacoesInternacaoPage";

type Props = {};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const PacienteProntuario: React.FC<Props> = (props) => {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  const { state } = useLocation();
  const paciente = state.pacienteSelecionado as Paciente;

  const theme = useTheme();

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        aria-label="full width tabs example"
      >
        <Tab label="Dados do Paciente" {...a11yProps(0)} />
        <Tab label="Receitas" {...a11yProps(1)} />
        <Tab label="Atestados" {...a11yProps(2)} />
        <Tab
          label="Encaminhamentos atendimento especializado"
          {...a11yProps(3)}
        />
        <Tab label="Solicitações de internação" {...a11yProps(3)} />
        {/* <Tab label="Encaminhamentos fisioterapia" {...a11yProps(3)} /> */}
        {/* <Tab label="Procedimentos de alto custo" {...a11yProps(3)} /> */}
      </Tabs>

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0}>
          <TabPanelDadosPaciente paciente={paciente} />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <ReceitasProntuario paciente={paciente} />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <AtestadosProntuario paciente={paciente} />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <EncaminhamentoEspecialistas paciente={paciente} />
        </TabPanel>

        <TabPanel value={value} index={4}>
          <SolicitacoesInternacaoPage paciente={paciente} />
        </TabPanel>
      </SwipeableViews>

      {value === 0 && (
        <Grid
          item
          xs={12}
          style={{ textAlign: "center", marginBottom: "1rem" }}
        >
          <Button
            startIcon={<FloppyDisk size={32} />}
            onClick={() => {
              setValue(value - 1);
            }}
            variant="contained"
          >
            Salvar
          </Button>
        </Grid>
      )}

      <Grid container>
        {value >= 1 && (
          <Grid
            item
            xs={6}
            style={{ textAlign: "center", marginBottom: "1rem" }}
          >
            <Button
              startIcon={<ArrowLeft size={32} />}
              onClick={() => {
                setValue(value - 1);
              }}
              variant="outlined"
            >
              Anterior
            </Button>
          </Grid>
        )}
        {value < 3 && (
          <Grid
            item
            xs={6}
            style={{ textAlign: "center", marginBottom: "1rem" }}
          >
            <Button
              endIcon={<ArrowRight size={32} />}
              onClick={() => {
                setValue(value + 1);
              }}
              variant="outlined"
            >
              Proximo
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PacienteProntuario;
