import HomePage from "../../components/Home/HomePage";
import HomeIcon from "@mui/icons-material/Home";
import { RouteType } from "../config";

const grupoInicio: RouteType = {
  path: "/",
  element: <HomePage />,
  state: "inicio",
  sidebarProps: {
    displayText: "Inicio",
    icon: <HomeIcon />
  }
};

export default grupoInicio;
