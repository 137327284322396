import { Outlet } from "react-router-dom";
import { Box, Toolbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import theme, { colors } from "../../theme";
import { useSessao } from "../../redux/sessao/sessaoHooks";
import LoginPage from "../App/Pages/Login/LoginPage";
import sizeConfigs from "../configs/sizeConfigs";
import Copyright from "./Copyrigth";
import colorConfigs from "../configs/colorConfigs";
import Drawer from "../common/Drawer";
import Header from "../BaseComponent/Header";

const MainLayout = () => {
  const [openSlideBar, setOpenSliderBar] = useState(false);
  const classes = useStyles();
  const [sessao] = useSessao();

  const [autorizado, setAutorizado] = useState(true);
  const handleDrawerToggle = () => {
    setOpenSliderBar(!openSlideBar);
  };

  useEffect(() => {
    if (!sessao.tokenJWT) {
      setAutorizado(false);
    } else {
      setAutorizado(true);
    }
  }, [sessao]);

  if (!autorizado) {
    return <LoginPage />;
  }

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Header drawerOpen={openSlideBar} onDrawerToggle={handleDrawerToggle} />
        <Box component="nav">
          <Drawer
            open={openSlideBar}
            handleDrawerClose={handleDrawerToggle}
            onOpen={() => setOpenSliderBar(true)}
            onClose={() => setOpenSliderBar(false)}
          />
        </Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: `calc(100% - ${sizeConfigs.sidebar.width})`,
            minHeight: "100vh",
            // backgroundColor: colorConfigs.mainBg,
          }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>

      <footer className={classes.footer}>
        <Copyright textColor={colors.secondary} />
      </footer>
    </>
  );
};

const useStyles = makeStyles({
  body: {
    "@media (max-width: 768px)": {
      height: "100vh",
      width: "100vw",
      padding: "15%",
    },
  },
  footer: {
    padding: theme.spacing(2),
    background: colors.primary,
  },
});

export default MainLayout;
