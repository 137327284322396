import { Alert, Card, Grid } from "@mui/material";
import { Encaminhamento } from "../../../../model/entities/Encaminhamento";
import Info from "../../../Generics/Info";
import dayjs from "dayjs";
import TextFieldGeneric from "../../../Generics/TextFieldGeneric";

import Container from "../../../Generics/Container";
import { useNavigate } from "react-router-dom";
import { ContraReferencia } from "../../../../model/entities/ContraReferencia";
import PontoAtendimentoField from "../../../Generics/Fields/PontoAtendimentoField";
import LISTA_CIDS from "../../../../tabelaCid.json";

type Props = {
  encaminhamento: Encaminhamento;
  contraReferencia: ContraReferencia;
  setContraReferencia: (cr: ContraReferencia) => void;
  apenasVisualizacao?: boolean;
};

const DadosEncaminhamentoPage: React.FC<Props> = (props) => {
  const {
    encaminhamento,
    contraReferencia,
    setContraReferencia,
    apenasVisualizacao,
  } = props;
  const navigate = useNavigate();

  return (
    <Grid display={"flex"} spacing={2} container>
      {!apenasVisualizacao && (
        <Grid item xs={2}>
          <Card
            style={{
              cursor: "pointer",
              marginRight: "15px",
              display: "flex",
              flexDirection: "column",
              margin: "0px 1rem 0px 1px",
              boxShadow: "1px 1px 5px #353353",
              position: "sticky",
              top: "0",
            }}
          >
            <a
              href="#referencia"
              onClick={() => {
                navigate(`/encaminhamentos/${encaminhamento.id}/#referencia`, {
                  state: { encaminhamentoSelecionado: encaminhamento },
                });
              }}
              style={{
                color: "inherit",
                textDecoration: "inherit",
                margin: "5px",
                padding: "1px",
              }}
            >
              Referência Encaminhamento
            </a>

            <a
              href="#contraReferencia"
              onClick={() => {
                navigate(
                  `/encaminhamentos/${encaminhamento.id}/#contraReferencia`,
                  {
                    state: { encaminhamentoSelecionado: encaminhamento },
                  }
                );
              }}
              style={{
                color: "inherit",
                textDecoration: "inherit",
                margin: "5px",
                padding: "1px",
              }}
            >
              Contra Referência
            </a>
          </Card>
        </Grid>
      )}

      <h2 style={{ color: "red", textAlign: "center" }}>
        {encaminhamento.pacienteAusente &&
          encaminhamento.motivoAusenciaPaciente && (
            <>
              {`Paciente ausente por motivo: ${encaminhamento.motivoAusenciaPaciente}`}
            </>
          )}
      </h2>
      <div>
        <Container style={{ marginBottom: "2rem" }}>
          <Grid container spacing={2}>
            <div id="referencia" />
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <h3>GUIA DE ENCAMINHAMENTO</h3>
              <h3>REFERÊNCIA</h3>
            </Grid>

            <Grid item xs={6}>
              <Info label="Nome do Cidadão">{encaminhamento.nomeCidadao}</Info>
            </Grid>

            <Grid item xs={3}>
              <Info label="CPF/CNS">{encaminhamento.cpfCns}</Info>
            </Grid>

            <Grid item xs={3}>
              <Info label="Classificação de Risco">
                {encaminhamento.classificacaoRisco}
              </Info>
            </Grid>

            <Grid item xs={3}>
              <Info label="Sexo">{encaminhamento.genero}</Info>
            </Grid>

            <Grid item xs={3}>
              <Info label="Idade">
                <>
                  {dayjs().diff(
                    dayjs(encaminhamento.dataNascimento).diff(
                      encaminhamento.dataNascimento,
                      "year"
                    ),
                    "year"
                  )}
                </>
              </Info>
            </Grid>

            <Grid item xs={3}>
              <Info label="Data de Nascimento">
                {dayjs(encaminhamento.dataNascimento).format("DD/MM/YYYY")}
              </Info>
            </Grid>

            <Grid item xs={3}>
              <Info label="Telefone">{encaminhamento.telefone}</Info>
            </Grid>

            <Grid item xs={6}>
              <Info label="Nome da Mãe">{encaminhamento.nomeMae}</Info>
            </Grid>

            <Grid item xs={6}>
              <Info label="Município de nascimento">
                {encaminhamento.municipioNascimento}
              </Info>
            </Grid>

            <Grid item xs={8}>
              <Info label="Unidade de saúde solicitante">
                {encaminhamento.unidadeSolicitante}
              </Info>
            </Grid>

            <Grid item xs={4}>
              <Info label="CNES">{encaminhamento.cnes}</Info>
            </Grid>

            <Grid item xs={8}>
              <Info label="Profissional solicitante">
                {encaminhamento.profissionalSolicitante}
              </Info>
            </Grid>

            <Grid item xs={4}>
              <Info label="CNS">{encaminhamento.cns}</Info>
            </Grid>

            <Grid item xs={6}>
              <Info label="Especilidade">{encaminhamento.especialidade}</Info>
            </Grid>

            <Grid item xs={6}>
              <Info label="Hipótese / Diagnóstico (CID10)">
                {LISTA_CIDS.find((item) => encaminhamento.hipotese)?.codigo} -{" "}
                {LISTA_CIDS.find((item) => encaminhamento.hipotese)?.nome}
              </Info>
            </Grid>
          </Grid>
        </Container>

        {(!apenasVisualizacao || contraReferencia.diagnostico) && (
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <h3>CONTRA - REFERÊNCIA</h3>
              </Grid>

              <Grid item xs={8}>
                <PontoAtendimentoField
                  pontoAtendimento={contraReferencia.pontoAtendimento}
                  setPontoAtendimento={(pa) => {
                    if (pa)
                      setContraReferencia({
                        ...contraReferencia,
                        pontoAtendimento: pa,
                      });
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <TextFieldGeneric
                  label={"AGENDA: Data e Hora"}
                  onChange={(value) => {
                    // setContraReferencia({
                    //   ...contraReferencia,
                    //   data: dayjs(value).toDate()
                    // });
                  }}
                  value={
                    contraReferencia.data
                      ? dayjs(contraReferencia.data).format("DD/MM/YYYY HH:mm")
                      : dayjs().format("DD/MM/YYYY HH:mm")
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <TextFieldGeneric
                  label={"Diagnóstico (CID10)"}
                  value={contraReferencia.diagnostico}
                  onChange={(value) => {
                    setContraReferencia({
                      ...contraReferencia,
                      diagnostico: value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextFieldGeneric
                  minRows={4}
                  label={"Parecer / conduta da especialidade"}
                  value={contraReferencia.parecer}
                  onChange={(value) => {
                    setContraReferencia({
                      ...contraReferencia,
                      parecer: value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextFieldGeneric
                  minRows={4}
                  label={"Observação"}
                  value={contraReferencia.observacao}
                  onChange={(value) => {
                    setContraReferencia({
                      ...contraReferencia,
                      observacao: value,
                    });
                  }}
                />
              </Grid>
            </Grid>
            <div id="contraReferencia" />
          </Container>
        )}

        {apenasVisualizacao && !contraReferencia.diagnostico && (
          <Grid item xs={12} style={{ margin: "5px" }}>
            <Alert elevation={6} variant="outlined" severity="info">
              Este encaminhamento não tem contra-referencia
            </Alert>
          </Grid>
        )}
      </div>
    </Grid>
  );
};

export default DadosEncaminhamentoPage;
