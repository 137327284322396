import { Medico } from "./Medico";
import { Paciente } from "./Paciente";
import { PontoAtendimento } from "./PontoAtendimento";

export class EncaminhamentoFisioterapia {
  id!: number;
  paciente!: Paciente;
  diagnostico!: string;
  descricaoQuadro!: string;
  inicioSintomasFisioterapia!: string;
  teveProcedimentoCirurgico!: boolean;
  procedimentoCirurgico!: string;
  quandoOcorreuCirurgia!: string;
  deixouRealizarAlgumaAtividade!: boolean;
  avd!: boolean;
  aivd!: boolean;
  outrasAtividadesDeixouRealizar!: string;
  profissao!: string;
  situacaoMercadoTrabalho!: string;
  ortese!: boolean;
  protese!: boolean;
  adaptacao!: boolean;
  qualEmUso!: string;
  jaEmUsoProtese!: boolean;
  dataSolicitacao!: Date;

  idMedico!: number;
  idPaciente!: number;
  idPontoAtendimento!: number;

  pontoAtendimento!: PontoAtendimento;
  medico!: Medico;
}
