import { AtestadoMedico } from "../model/entities/Atestado";
import { DadosImprimirAtestado } from "../model/types/DadosImprimirAtestado";
import { ResponseGenericType } from "../model/types/ResponseType";
import { FindManyReq } from "../model/types/TypeormTypes";
import api from "./api";

const PREFIX = "";
const ROUTE = "atestado";

class atestadoApi {
  async getMany() {
    try {
      const res = await api.get<ResponseGenericType<AtestadoMedico[]>>(
        `/${ROUTE}`
      );
      const data = res.data.content;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getByPaciente(idPaciente: number) {
    try {
      const res = await api.get<AtestadoMedico[]>(
        `/${ROUTE}/getByPaciente/${idPaciente}`
      );
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async count(query: FindManyReq<AtestadoMedico>) {
    try {
      const res = await api.get<number>(`/${ROUTE}/count`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async insert(atestado: AtestadoMedico) {
    try {
      const res = await api.post<ResponseGenericType<AtestadoMedico[]>>(
        `/${ROUTE}`,
        atestado
      );

      const data = res.data.content;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async imprimir(dados: DadosImprimirAtestado) {
    try {
      const res = await api.post<string>(`/${ROUTE}/imprimir`, dados, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      });

      const blob = new Blob([res.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      // Abra o PDF em uma nova janela ou guia
      window.open(url, "_blank");
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new atestadoApi();
