import { Grid, IconButton } from "@mui/material";
import { makeStyles } from "@material-ui/core";

import Container from "../../../Generics/Container";

import { Paciente } from "../../../../model/entities/Paciente";

import { useEffect, useState } from "react";
import { Printer } from "@phosphor-icons/react";
import { Receita } from "../../../../model/entities/Receita";
import dayjs from "dayjs";
import logoFormiga from "../../../../images/brasao_formiga.png";
import TextFieldGeneric from "../../../Generics/TextFieldGeneric";
import receitaApi from "../../../../api/receitaApi";
import { useSessao } from "../../../../redux/sessao/sessaoHooks";

type Props = {
  paciente: Paciente;
  receita: Receita;
  setReceita: (r: Receita) => void;
  apenasVisualizacao?: boolean;
};

const Receituario: React.FC<Props> = (props) => {
  const { paciente, receita, setReceita, apenasVisualizacao } = props;

  const [sessao] = useSessao();

  useEffect(() => {
    setReceita({
      ...receita,
      data: dayjs().toDate(),
      idPontoAtendimento: 1,
      idMedico: sessao.usuario?.id ?? 0,
      idPaciente: paciente.id,
    });
  }, []);

  const imprimir = async () => {
    try {
      await receitaApi.imprimir({
        medico: sessao.usuario?.nome ?? "",
        paciente: paciente.nome,
        prescricao: receita.prescricao,
      });
    } catch (erro: any) {
      console.log("oi");
    }
  };

  return (
    <Container page style={{ height: "78vh" }}>
      {apenasVisualizacao && (
        <>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <img
              src={logoFormiga}
              alt="Brasão Município Formiga-MG"
              style={{ maxWidth: "8%" }}
            />
          </Grid>

          <Grid item xs={12} style={{ textAlign: "center" }}>
            <h1>Prefeitura Municipal de Formiga</h1>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <h1 style={{ textAlign: "center" }}>Receituario</h1>
      </Grid>

      <Grid container spacing={1} style={{ height: "40vh" }}>
        <Grid item xs={12}>
          <TextFieldGeneric
            label="Paciente "
            value={paciente.nome}
            disabled={apenasVisualizacao}
            onChange={(value) => {
              // setPrescricao(value);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextFieldGeneric
            disabled={apenasVisualizacao}
            label="Médico"
            value={sessao.usuario?.nome ?? ""}
            onChange={(value) => {
              // setPrescricao(value);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextFieldGeneric
            disabled={apenasVisualizacao}
            label="Prescrição"
            value={receita.prescricao}
            onChange={(value) => {
              setReceita({ ...receita, prescricao: value });
            }}
            minRows={12}
          />
        </Grid>

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <IconButton
            onClick={() => {
              imprimir();
            }}
          >
            <Printer size={32} style={{ margin: "10px 10px 10px 2px" }} />
            Imprimir
          </IconButton>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Receituario;
const useStyles = makeStyles({
  centerTexto: {
    marginTop: "5%",
  },
});
