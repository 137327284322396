import { Button, Divider, FormControl, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import TextFieldGeneric from "../../../Generics/TextFieldGeneric";
import { Divide, PaperPlaneTilt } from "@phosphor-icons/react";
import PacienteField from "../../../Generics/Fields/PacienteField";
import { Paciente } from "../../../../model/entities/Paciente";
import PontoAtendimentoField from "../../../Generics/Fields/PontoAtendimentoField";
import { PontoAtendimento } from "../../../../model/entities/PontoAtendimento";
import SexoField from "../../../Generics/Fields/SexoField";
import DatePickerGeneric from "../../../Generics/DatePickerGeneric";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import encaminhamentoApi from "../../../../api/encaminhamentoApi";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import CidsField from "../../../Generics/Fields/CidsField";

type Props = {};

const FormularioNovoEncaminhamento: React.FC<Props> = (props) => {
  const [nomeCidadao, setNomeCidadao] = useState("");
  const [cpfCns, setCpfCns] = useState("");
  const [classificacaoRisco, setClassificacaoRisco] = useState("");
  const [genero, setGenero] = useState("");
  const [dataNascimento, setDataNascimento] = useState(dayjs());
  const [nomeMae, setNomeMae] = useState("");
  const [telefone, setTelefone] = useState("");
  const [municipioNascimento, setMunicipioNascimento] = useState("");
  const [profissionalSolicitante, setProfissionalSolicitante] = useState("");
  const [ubsSolicitante, setUbsSolicitante] = useState("");
  const [cnes, setCnes] = useState("");

  const [cns, setCns] = useState("");
  const [especialidade, setEspecialidade] = useState("");
  const [hipoteseDiagnostico, setHipoteseDiagnostico] = useState("");
  const [motivoEncaminhamento, setMotivoEncaminhamento] = useState("");
  const [observacao, setObservacao] = useState("");

  const [paciente, setPaciente] = useState<Paciente | null>(null);
  const [pontoAtendimento, setPontoAtendimento] =
    useState<PontoAtendimento | null>(null);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const schema = object({
    name: string()
      .required("O nome do cidadão é obrigatório")
      .min(3, "O nome precisa ter ao menos 3 caracteres"),
    cpf: string().required("Informe o CPF ou CNS"),
    classificacaoRisco: string().required("Qual a classificação de risco?"),
    profissionalSolicitante: string().required(
      "Informe o profissional solicitante"
    ),
  });
  const {
    register,
    handleSubmit: handleSubmitForm,
    watch,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (paciente) {
      setNomeCidadao(paciente.nome);
      setCpfCns(paciente.cpf);
      setGenero(paciente.genero);
      //pode ser que quando setar o paciente aqui quebre rs
      setDataNascimento(dayjs(paciente.dataNascimento));
      setNomeMae(paciente.nomeMae);
      setTelefone(paciente.telefone);
      setMunicipioNascimento(paciente.municipioNascimento);
    }
  }, [paciente]);

  useEffect(() => {
    if (pontoAtendimento) {
      setUbsSolicitante(pontoAtendimento.nome);
      setCnes(pontoAtendimento.cnes);
    }
  }, [pontoAtendimento]);

  const salvarEncaminhamento = async () => {
    try {
      const cadastroEncaminhamento = encaminhamentoApi.insert({
        id: 0,
        classificacaoRisco,
        cnes,
        cns,
        cpfCns,
        especialidade,
        genero,
        hipotese: hipoteseDiagnostico,
        motivoEncaminhamento,
        municipioNascimento,
        idPaciente: paciente ? paciente.id : 0,
        nomeCidadao,
        nomeMae,
        observacao,
        unidadeSolicitante: ubsSolicitante,
        telefone,
        profissionalSolicitante,
        dataEncaminhamento: dayjs().toDate(),
        dataNascimento: dataNascimento.toDate(),
        pacienteAusente: false,
        motivoAusenciaPaciente: "",
        paciente: new Paciente(),
      });

      toast.promise(cadastroEncaminhamento, {
        pending: {
          render() {
            setLoading(true);
            return "Cadastrando encaminhamento";
          },
        },
        success: {
          render() {
            setLoading(false);
            navigate("/encaminhamentos/listagem");
            return "Encaminhamento enviado";
          },
        },
        error:
          "Erro ao cadastrar encaminhamento. A equipe de TI, foi notificada",
      });
    } catch (erro) {
      setLoading(false);
      console.log("Erro ao cadastrar encaminhamento", erro);
    }
  };
  const handleSubmit = async (data: any) => {
    console.log("será que tem erro?");
    if (Object.keys(errors).length === 0) {
      // Se não houver erros, proceda com a submissão do formulário
      console.log("Dados do formulário:", data);
      // Adicione aqui a lógica para enviar os dados para o servidor, etc.
      await salvarEncaminhamento();
    } else {
      // Se houver erros, você pode optar por lidar com eles de alguma forma
      console.error("Houve erros de validação:", errors);
    }
  };

  return (
    <form onSubmit={handleSubmitForm(handleSubmit)}>
      <Grid container xs={12} sm={12} spacing={1}>
        <Grid item xs={12} style={{ marginBottom: "15px" }}>
          <PacienteField sx={1140} setPaciente={(obj) => setPaciente(obj)} />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextFieldGeneric
                {...field}
                label="Nome do Cidadão"
                value={nomeCidadao}
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ""}
                onChange={(value) => {
                  field.onChange(value);
                  setNomeCidadao(value);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            name="cpf"
            control={control}
            render={({ field }) => (
              <TextFieldGeneric
                {...field}
                label="CPF/CNS *"
                value={cpfCns}
                error={!!errors.cpf}
                helperText={errors.cpf ? errors.cpf.message : ""}
                onChange={(value) => {
                  field.onChange(value);
                  setCpfCns(value);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="classificacaoRisco"
            control={control}
            render={({ field }) => (
              <TextFieldGeneric
                {...field}
                label="Classificação de risco *"
                value={classificacaoRisco}
                error={!!errors.classificacaoRisco}
                helperText={
                  errors.classificacaoRisco
                    ? errors.classificacaoRisco.message
                    : ""
                }
                onChange={(value) => {
                  field.onChange(value);
                  setClassificacaoRisco(value);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <SexoField sx={378} setSexo={(value) => setGenero(value)} />
        </Grid>

        <Grid item xs={4}>
          <DatePickerGeneric
            value={dataNascimento}
            label="Data de nascimento"
            setValue={(value) => {
              setDataNascimento(value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldGeneric
            label="Telefone *"
            value={telefone}
            // disabled={loading}
            onChange={(value) => {
              setTelefone(value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldGeneric
            label="Nome da mãe"
            value={nomeMae}
            // disabled={loading}
            onChange={(value) => {
              setNomeMae(value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldGeneric
            label="Municipio do Nascimento"
            value={municipioNascimento}
            // disabled={loading}
            onChange={(value) => {
              setMunicipioNascimento(value);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider style={{ marginBottom: 5, marginTop: 20 }} />
        </Grid>

        <Grid item xs={8}>
          <PontoAtendimentoField
            label="P.A solicitante *"
            pontoAtendimento={pontoAtendimento}
            setPontoAtendimento={(obj) => {
              setPontoAtendimento(obj);
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextFieldGeneric
            label="CNES"
            value={cnes}
            // disabled={loading}
            onChange={(value) => {
              setCnes(value);
            }}
          />
        </Grid>

        <Grid item xs={8}>
          <Controller
            name="profissionalSolicitante"
            control={control}
            render={({ field }) => (
              <TextFieldGeneric
                {...field}
                label="Profissional solicitante *"
                value={profissionalSolicitante}
                error={!!errors.profissionalSolicitante}
                helperText={
                  errors.profissionalSolicitante
                    ? errors.profissionalSolicitante.message
                    : ""
                }
                onChange={(value) => {
                  field.onChange(value);
                  setProfissionalSolicitante(value);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <TextFieldGeneric
            label="CNS"
            value={cns}
            // disabled={loading}
            onChange={(value) => {
              setCns(value);
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextFieldGeneric
            label="Especialidade *"
            value={especialidade}
            // disabled={loading}
            onChange={(value) => {
              setEspecialidade(value);
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <CidsField
            codigoCid={hipoteseDiagnostico}
            setCid={(value) => {
              if (value) setHipoteseDiagnostico(value.codigo);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextFieldGeneric
            label="Motivo do encaminhamento *"
            value={motivoEncaminhamento}
            // disabled={loading}
            onChange={(value) => {
              setMotivoEncaminhamento(value);
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextFieldGeneric
            label="Observação"
            value={observacao}
            // disabled={loading}
            onChange={(value) => {
              setObservacao(value);
            }}
          />
        </Grid>

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            type="submit"
            style={{ border: "1px solid ligthBlue", borderRadius: "22px" }}
            variant="contained"
            startIcon={<PaperPlaneTilt size={24} />}
          >
            Gravar encaminhamento
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default FormularioNovoEncaminhamento;
