import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid/DataGrid";

type Props = {
  colunas: any[];
  linhas: any[];
  loading: boolean;
  totalLinhas: number;
  onRowClick?: (params: any) => void;
  pageSizeOptions: number[];
  onPageChange: (pageNumber: number) => void;
  disableSelectionOnClick?: boolean;
};

const TableGeneric: React.FC<Props> = (props) => {
  const { colunas, linhas, loading, totalLinhas, onRowClick, pageSizeOptions } =
    props;

  return (
    <Grid container xs={12} sm={12} spacing={2}>
      <DataGrid
        columns={colunas}
        rows={linhas}
        loading={loading}
        pageSizeOptions={pageSizeOptions}
        rowCount={totalLinhas}
        onRowClick={(params) => {
          if (onRowClick) onRowClick(params);
        }}
        style={{
          height: 400,
          textAlign: "center",
          border: "1px solid #ddd",
          boxShadow: "1px 1px 5px #353353",
          borderRadius: "10px",
          padding: "10px"
        }}
      />
    </Grid>
  );
};

export default TableGeneric;
