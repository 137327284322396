import { PontoAtendimento } from "./PontoAtendimento";

export class ContraReferencia {
  id!: number;

  data!: Date;

  parecer!: string;

  diagnostico!: string;

  observacao!: string;

  profissional!: string;

  pontoAtendimento!: PontoAtendimento;
}
