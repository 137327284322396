import { Card, Grid, IconButton } from "@mui/material";
import { makeStyles } from "@material-ui/core";

import dayjs from "dayjs";
import Container from "../../../Generics/Container";
import { Paciente } from "../../../../model/entities/Paciente";
import TextFieldGeneric from "../../../Generics/TextFieldGeneric";

import { useNavigate } from "react-router-dom";
import { Printer } from "@phosphor-icons/react";

import { EncaminhamentoFisioterapia } from "../../../../model/entities/EncaminhamentoFisioterapia";

import { useEffect } from "react";
import { Encaminhamento } from "../../../../model/entities/Encaminhamento";

type Props = {
  paciente: Paciente;
  encaminhamentoSelecionado: Encaminhamento;
  encaminhamentoFisio: EncaminhamentoFisioterapia;
  setEncaminhamentoFisio: (e: EncaminhamentoFisioterapia) => void;
};

const ProcedimentoAltoCustoPage: React.FC<Props> = (props) => {
  const {
    paciente,
    encaminhamentoFisio,
    setEncaminhamentoFisio,
    encaminhamentoSelecionado
  } = props;

  const navigate = useNavigate();

  useEffect(() => {
    setEncaminhamentoFisio({
      ...encaminhamentoFisio,
      idMedico: 1,
      idPontoAtendimento: 1,
      idPaciente: 1,
      dataSolicitacao: dayjs().toDate()
    });
  }, []);

  return (
    <Grid display={"flex"} spacing={2}>
      <Grid item xs={2} style={{}}>
        <Card
          style={{
            cursor: "pointer",
            marginRight: "15px",
            display: "flex",
            flexDirection: "column",
            margin: "0px 1rem 0px 1px",
            boxShadow: "1px 1px 5px #353353",
            // background: "red",
            position: "sticky",
            top: "0"
          }}
        >
          <a
            href="#procedimentoSolicitado"
            onClick={() => {
              navigate(
                `/encaminhamentos/${encaminhamentoSelecionado.id}/#procedimentoSolicitado`,
                {
                  state: { encaminhamentoSelecionado }
                }
              );
              // setExpandirProcedimentoSolicitado(true);
            }}
            style={{
              color: "inherit",
              textDecoration: "inherit",
              margin: "5px",
              padding: "px"
            }}
          >
            Procedimento Solicitado
          </a>

          <a
            href="#justificativa"
            onClick={() => {
              navigate(
                `/encaminhamentos/${encaminhamentoSelecionado.id}/#justificativa`,
                {
                  state: { encaminhamentoSelecionado }
                }
              );
              // setExpandirProcedimentoSolicitado(true);
            }}
            style={{
              color: "inherit",
              textDecoration: "inherit",
              margin: "5px",
              padding: "1px"
            }}
          >
            Justificativa Solicitação
          </a>
        </Card>
      </Grid>

      <Grid container spacing={2} style={{ margin: "15px" }}>
        <Container
          style={{ marginBottom: "2rem", boxShadow: "1px 1px 5px #353353" }}
        >
          <div id="procedimentoSolicitado" />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3>Procedimento solicitado</h3>
            </Grid>
            <Grid item xs={12}>
              <TextFieldGeneric
                label="Código sus do procedimento"
                value={encaminhamentoFisio.diagnostico}
                onChange={(value) => {
                  setEncaminhamentoFisio({
                    ...encaminhamentoFisio,
                    diagnostico: value
                  });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextFieldGeneric
                label="Nome do procedimento"
                value={encaminhamentoFisio.outrasAtividadesDeixouRealizar}
                onChange={(value) => {
                  setEncaminhamentoFisio({
                    ...encaminhamentoFisio,
                    outrasAtividadesDeixouRealizar: value
                  });
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextFieldGeneric
                label="QTD"
                value={encaminhamentoFisio.outrasAtividadesDeixouRealizar}
                onChange={(value) => {
                  setEncaminhamentoFisio({
                    ...encaminhamentoFisio,
                    outrasAtividadesDeixouRealizar: value
                  });
                }}
              />
            </Grid>
          </Grid>
        </Container>

        <Container
          style={{ marginBottom: "2rem", boxShadow: "1px 1px 5px #353353" }}
        >
          <div id="justificativa" />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3>Justificativa do procedimento solicitado</h3>
            </Grid>

            <Grid item xs={12}>
              <TextFieldGeneric
                label="História da doença atual"
                minRows={2}
                value={encaminhamentoFisio.qualEmUso}
                onChange={(value) => {
                  setEncaminhamentoFisio({
                    ...encaminhamentoFisio,
                    qualEmUso: value
                  });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldGeneric
                label="Exame físico"
                value={encaminhamentoFisio.qualEmUso}
                onChange={(value) => {
                  setEncaminhamentoFisio({
                    ...encaminhamentoFisio,
                    qualEmUso: value
                  });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldGeneric
                label="Tratamento realizado"
                value={encaminhamentoFisio.qualEmUso}
                onChange={(value) => {
                  setEncaminhamentoFisio({
                    ...encaminhamentoFisio,
                    qualEmUso: value
                  });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldGeneric
                label="Exames complementares"
                value={encaminhamentoFisio.qualEmUso}
                onChange={(value) => {
                  setEncaminhamentoFisio({
                    ...encaminhamentoFisio,
                    qualEmUso: value
                  });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldGeneric
                label="Hipotese diagnostica"
                value={encaminhamentoFisio.qualEmUso}
                onChange={(value) => {
                  setEncaminhamentoFisio({
                    ...encaminhamentoFisio,
                    qualEmUso: value
                  });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldGeneric
                label="CID 10 principal"
                value={encaminhamentoFisio.qualEmUso}
                onChange={(value) => {
                  setEncaminhamentoFisio({
                    ...encaminhamentoFisio,
                    qualEmUso: value
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} style={{ textAlign: "center" }}>
              <IconButton onClick={() => {}}>
                <Printer size={32} style={{ margin: "10px 10px 10px 0px" }} />
                Imprimir
              </IconButton>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default ProcedimentoAltoCustoPage;
const useStyles = makeStyles({
  centerTexto: {
    marginTop: "5%"
  }
});
