import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import { routes } from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import BloqueioEmpresaPage from "./components/App/Pages/BloqueioEmpresa/BloqueioEmpresaPage";
import { analytics } from "./firebase/firebaseConfig";

function App() {
  const [status, setStatus] = useState("");

  const RoutesListener = () => {
    const location = useLocation();

    useEffect(() => {
      // analytics.logEvent(location.pathname, {
      //   parametro1: "valor1",
      //   parametro2: "valor2",
      // });
      analytics.logEvent(location.pathname);
    }, [location]);

    return <></>;
  };

  useEffect(() => {
    getStatus();
  }, []);

  const getStatus = async () => {
    try {
      // const response = await axios.get("http://3.88.19.200:3000/getStatus");
      setStatus("L");
    } catch (error) {
      console.error("Erro ao fazer requisição:", error);
      throw error;
    }
  };

  if (status !== "L") {
    return <BloqueioEmpresaPage />;
  }

  return (
    <BrowserRouter>
      <ToastContainer />
      <RoutesListener />
      <Routes>
        <Route path="/" element={<MainLayout />}>
          {routes}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
