import { EncaminhamentoFisioterapia } from "../model/entities/EncaminhamentoFisioterapia";
import { ResponseGenericType } from "../model/types/ResponseType";
import { FindManyReq } from "../model/types/TypeormTypes";
import api from "./api";

const PREFIX = "";
const ROUTE = "encaminhamentoFisioterapia";

class encaminhamentoFisioterapiaApi {
  async getMany(query: FindManyReq<EncaminhamentoFisioterapia>) {
    try {
      const res = await api.get<
        ResponseGenericType<EncaminhamentoFisioterapia[]>
      >(`/${ROUTE}`);
      const data = res.data.content;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async count(query: FindManyReq<EncaminhamentoFisioterapia>) {
    try {
      const res = await api.get<number>(`/${ROUTE}/count`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async insert(encaminhamentoFisioterapia: EncaminhamentoFisioterapia) {
    try {
      const res = await api.post<
        ResponseGenericType<EncaminhamentoFisioterapia[]>
      >(`/${ROUTE}`, encaminhamentoFisioterapia);

      const data = res.data.content;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new encaminhamentoFisioterapiaApi();
