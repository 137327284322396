const sizeConfigs = {
  sidebar: {
    width: "256px",
  },
  bodyMobile: {
    "@media (max-width: 768px)": {
      height: "100vh",
      width: "100vw",
      marginTop: "100px",
    },
  },
};

export default sizeConfigs;
