import { useDispatch, useSelector } from "react-redux";
import { setCfgGeralAction } from "./cfgGeralActions";
import { RootAppState } from "../store";
import { CfgGeralState } from "./cfgGeralTypes";

export const useCfgGeral = (): [
  CfgGeralState,
  (cfg: CfgGeralState) => void
] => {
  const dispatch = useDispatch();
  const cfgGeral = useSelector((state: RootAppState) => state.cfgGeral);
  const setCfgGeral = (cfg: CfgGeralState) => {
    const action = setCfgGeralAction(cfg);
    dispatch(action);
  };

  return [cfgGeral, setCfgGeral];
};
