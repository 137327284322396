export class Endereco {
  logradouro!: string;

  cep!: string;

  bairro!: string;

  cidade!: string;

  uf!: string;

  numero!: string;
}
